import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Col, Row, Spinner } from "reactstrap";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import "../../components/Dashboard.css";
import * as API from "../../utils/api";
import ToggleableBoxComponent from "./ToggleableBoxComponent";
import Modal from "react-bootstrap/Modal";
import * as CardStyles from "../../styles/CardStyles";

export default class GeneralConfigsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialConfigurationPolicies: [],
      backupFrequency: "",
      backupStartTime: "",
      backupDestination: "",
      backupFolderPath: "C:/Backup",
      scanFileTypes: "",
      usbDeviceControlPolicy: "",
      editModeActive: false,
      showFileScanModal: false,
    };
  }

  componentDidMount() {
    this.fetchConfigurationPoliciesAndPopulateState();
  }

  fetchConfigurationPoliciesAndPopulateState() {
    API.configurationPolicyGetAPI()
      .then((data) => {
        const configPolicies = data.entity;
        this.populateStateUsingConfigPolicies(configPolicies);
        this.setState({
          initialConfigurationPolicies: getClone(configPolicies),
        });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  populateStateUsingConfigPolicies(configPolicies) {
    for (const configPolicy of configPolicies) {
      if (configPolicy.policyName === "scan") {
        const configValues = configPolicy.configValues;
        for (const configValue of configValues) {
          if (configValue.configName === "FileTypesToScan") {
            this.setState({ scanFileTypes: configValue.rawValue });
          }
        }
      } else if (configPolicy.policyName === "general") {
        const configValues = configPolicy.configValues;
        for (const configValue of configValues) {
          if (configValue.configName === "UsbDeviceControl") {
            this.setState({ usbDeviceControlPolicy: configValue.rawValue });
          }
        }
      } else if (configPolicy.policyName === "backup") {
        const configValues = configPolicy.configValues;
        for (const configValue of configValues) {
          if (configValue.configName === "Frequency") {
            this.setState({ backupFrequency: configValue.rawValue });
          } else if (configValue.configName === "BackupTaskStartAt") {
            this.setState({ backupStartTime: configValue.rawValue });
          } else if (configValue.configName === "Destination") {
            this.setState({ backupDestination: configValue.rawValue });
          } else if (configValue.configName === "FolderPath") {
            this.setState({ backupFolderPath: configValue.rawValue });
          }
        }
      }
    }
  }

  cancelEdit() {
    this.populateStateUsingConfigPolicies(
      this.state.initialConfigurationPolicies
    );
    this.setState({ editModeActive: false });
  }

  submitUpdatedDocumentScanConfigs() {
    const updatedScanConfigs = {
      policyName: "scan",
      configValues: [
        {
          configName: "FileTypesToScan",
          rawValue: this.state.scanFileTypes,
        },
      ],
    };
    API.updateConfigurationPolicy(updatedScanConfigs)
      .then((data) => {
        console.log(JSON.stringify(data));
        this.submitUpdatedBackupConfigs();
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  submitUpdatedBackupConfigs() {
    const updatedBackupConfigs = {
      policyName: "backup",
      configValues: [
        {
          configName: "Frequency",
          rawValue: this.state.backupFrequency,
        },
        {
          configName: "BackupTaskStartAt",
          rawValue: this.state.backupStartTime,
        },
        {
          configName: "Destination",
          rawValue: this.state.backupDestination,
        },
        {
          configName: "FolderPath",
          rawValue: this.state.backupFolderPath,
        }
      ],
    };
    API.updateConfigurationPolicy(updatedBackupConfigs)
      .then((data) => {
        this.submitUpdatedGeneralConfigs();
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  submitUpdatedGeneralConfigs() {
    const updatedGeneralConfigs = {
      policyName: "general",
      configValues: [
        {
          configName: "UsbDeviceControl",
          rawValue: this.state.usbDeviceControlPolicy,
        },
      ],
    };
    API.updateConfigurationPolicy(updatedGeneralConfigs)
      .then((data) => {
        this.setState({ initialConfigurationPolicies: [] });
        this.fetchConfigurationPoliciesAndPopulateState();
        this.setState({ editModeActive: false });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (
      this.state.initialConfigurationPolicies &&
      this.state.initialConfigurationPolicies.length === 0
    ) {
      return (
        <div>
          <Card className="bm-center-card" style={{ minHeight: "300px" }}>
            <Card.Header
              style={CardStyles.cardHeader}
              className="bm-tab-heading"
            >
              General Configurations
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" size="sm" color="primary" />{" "}
              <Spinner type="grow" size="sm" color="secondary" />
              Loading.....
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.props.isToggleAttempted && !this.state.editModeActive) {
      this.props.acceptToggle(true);
    }

    return (
      <div style={{ marginBottom: "12px" }}>
        <Modal show={this.props.isToggleAttempted}>
          <Modal.Header closeButton={false}>
            <Modal.Title>Are you sure you want to discard your changes?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your recent configuration changes are not saved yet.
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "start" }}>
            <Button
              variant="primary"
              onClick={() => {
                this.props.acceptToggle(true);
                this.cancelEdit();
              }}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                this.props.acceptToggle(false);
                this.setState({ editModeActive: true });
              }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>

        <Row>
          <Modal
            show={this.state.showFileScanModal}
            onHide={() => {
              this.setState({ showFileScanModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>File Types Classification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Office Documents: .docx, .rtf, .xlsx, .pptx, .pdf, etc.
              <br />
              Archives: 7zip, Gzip, etc.
              <br />
              Text Files: .txt, .log, etc.
              <br />
              Source Code: .json, .py, .java, .sql, etc.
              <br />
              Web Files: .html, .css, .xml, etc.
              <br />
              Media Files: .mp3, .mp4, .jpg, .bmp, .avi, .mov, etc.
            </Modal.Body>
          </Modal>

          <Col sm="4">
            <Card className="bm-center-card" style={{ minHeight: "390px" }} >
              <Card.Header
                style={CardStyles.cardHeader}
                className="bm-tab-heading"
              >
                Data Backup
              </Card.Header>
              <Card.Body class="bm-card-body">
                <div style={{ margin: "10px", color: "#1E90FF" }}>
                  {this.getScanFrequencyConfigComponent()}
                  <br />
                  {this.getStartTimeComponent()}
                  <br />
                  {this.getScanDestination()}
                  <br />
                  {this.getFolderPathComponent()}
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col sm="4">{this.getFileTypeScanComponent()}</Col>

          <Col sm="4">
            <Card className="bm-center-card" style={{ minHeight: "390px" }}>
            <div style={{ margin: "10px", color: "#1E90FF" }}/>
              <Card.Header
                style={CardStyles.cardHeader}
                className="bm-tab-heading"
              >
                USB Device Control
              </Card.Header>
              <Card.Body class="bm-card-body">
              <div style={{ margin: "10px", color: "#1E90FF",textAlign: "left" }}>
                  {this.getUsbDeviceControlConfigComponent()}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Body style={{ padding: "8px" }}>
            <div style={{ color: "#1E90FF", textAlign: "left" }}>
              {this.getConfigButtons()}
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  getFileTypeScanComponent() {
    return (
      <Card className="bm-center-card" style={{ height: "390px" }}>
         <div style={{ margin: "35px", color: "#1E90FF"}}/>
        <Card.Header style={CardStyles.cardHeader} className="bm-tab-heading">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            File Scan
            <Button
              variant="link"
              onClick={() => {
                this.setState({ showFileScanModal: true });
              }}
            >
              <Badge
                as="button"
                pill
                variant="primary"
                style={{ fontSize: "10px", height: "16px" }}
              >
                ?
              </Badge>
            </Button>
          </div>
        </Card.Header>
        <p style={{ margin: "8px", textAlign: "left" }}>File Types to Scan:</p>
        <Card.Body class="bm-card-body">
          <div className="row leftalign">
            {JSON.parse(this.state.scanFileTypes).map((data, key) => {
              return (
                <ToggleableBoxComponent
                  key={key}
                  id={data.fileType + "_" + key}
                  activated={data.active}
                  text={data.fileType}
                  showToggleSwitch={this.state.editModeActive}
                  colWidth="col-sm-4"
                  onToggle={(event) => {
                    const currentDocumentsTypeConfig = JSON.parse(
                      this.state.scanFileTypes
                    );
                    currentDocumentsTypeConfig[
                      key
                    ].active = !currentDocumentsTypeConfig[key].active;
                    this.setState({
                      scanFileTypes: JSON.stringify(currentDocumentsTypeConfig),
                    });
                  }}
                />
              );
            })}
          </div>
        </Card.Body>
      </Card>
    );
  }

  getScanFrequencyConfigComponent() {
    return (
      <Row style={{ textAlign: "left" }}>
        <Col sm="5">
          <p>Frequency:</p>
        </Col>
        <Col sm="7">
          {this.getFormControl(
            this.state.backupFrequency,
            ["Hourly", "Daily", "Weekly"],
            (event) => {
              this.setState({ backupFrequency: event.target.value });
            }
          )}
        </Col>
      </Row>
    );
  }

  getFormControl(value, valueArr, onChangeAction) {
    if (this.state.editModeActive) {
      return (
        <Form.Control
          style={{ color: "#1E90FF", background: "white" }}
          as="select"
          value={value}
          onChange={onChangeAction}
        >
          {valueArr.map((val, index) => {
            return <option key={index}>{val}</option>;
          })}
        </Form.Control>
      );
    } else {
      return (
        <Form.Control disabled={true} value={value} onChange={onChangeAction} />
      );
    }
  }

  getStartTimeComponent() {
    return (
      <Row style={{ textAlign: "left" }}>
        <Col sm="5">
          <p style={{ textAlign: "left" }}>Start Time:</p>
        </Col>
        <Col sm="7">
          <Form.Control
            disabled={!this.state.editModeActive}
            value={this.state.backupStartTime}
            onChange={(event) => {
              this.setState({ backupStartTime: event.target.value });
            }}
          />
        </Col>
      </Row>
    );
  }

  getFolderPathComponent() {
    return (
      <Row style={{ textAlign: "left" }}>
        <Col sm="5">
          <p style={{ textAlign: "left" }}>Folder Path</p>
        </Col>
        <Col sm="7">
          <Form.Control
            disabled={!this.state.editModeActive}
            value={this.state.backupFolderPath}
            onChange={(event) => {
              this.setState({ backupFolderPath: event.target.value });
            }}
          />
        </Col>
      </Row>
    );
  }

  getScanDestination() {
    return (
      <Row style={{ textAlign: "left" }}>
        <Col sm="5">
          <p style={{ textAlign: "left" }}>Destination:</p>
        </Col>
        <Col sm="7">
          {this.getFormControl(
            this.state.backupDestination,
            [
                "Local disk", 
                "Network Share", 
                // "Cloud Repository"
            ],
            (event) => {
              this.setState({ backupDestination: event.target.value });
            }
          )}
        </Col>
      </Row>
    );
  }

  getUsbDeviceControlConfigComponent() {
    return (
      <Row style={{ textAlign: "left" }}>
        <Col sm="5">
          <p style={{ textAlign: "left" }}>Policy:</p>
        </Col>
        <Col sm="7">
          {this.getFormControl(
            this.state.usbDeviceControlPolicy,
            [
              "Allow but Monitor",
              "Disable Read & Write",
              "Disable Write",
              "Encrypt",
            ],
            (event) => {
              this.setState({ usbDeviceControlPolicy: event.target.value });
            }
          )}
        </Col>
      </Row>
    );
  }

  getConfigButtons() {
    if (this.state.editModeActive) {
      return (
        <div
          className="row"
          style={{ marginLeft: "12px", marginRight: "12px" }}
        >
          <Button
            variant="primary"
            className="col-sm-1"
            style={{ marginLeft: "8px", marginRight: "8px" }}
            onClick={() => {
              this.submitUpdatedDocumentScanConfigs();
            }}
          >
            Save
          </Button>
          <Button
            variant="secondary"
            className="col-sm-1"
            style={{ marginLeft: "8px", marginRight: "8px" }}
            onClick={() => {
              this.cancelEdit();
            }}
          >
            Cancel
          </Button>
        </div>
      );
    } else {
      return (
        <div
          className="row"
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Button
            className="col-sm-1"
            variant="primary"
            onClick={() => {
              this.setState({ editModeActive: true });
            }}
          >
            Edit
          </Button>
        </div>
      );
    }
  }
}

const getClone = (object) => {
  return JSON.parse(JSON.stringify(object));
};
