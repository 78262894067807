import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import "../../../components/Dashboard.css";
import Top5RiskyUsers from "./Data/Top5RiskyUsers";
import BrowingHostByCategory from "../../Monitor/Events/Mon-BrowingHostByCategory";
import MonPolicyVoilationBySeverity from "./Data/Mon-PolicyVoilationsBySeverity";

export default class SummaryOverview extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    return (
      <div className="bm-dashboard-partition">
        <Nav card>
          <NavItem>
            <NavLink
              className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
              onClick={() => {
                this.toggle("1");
              }}
            >
              Monitor
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" className="dashboard-tab-bg">
            <Row>
              <Col sm="4">
                <MonPolicyVoilationBySeverity />
              </Col>
              <Col sm="4">
                <Top5RiskyUsers />
              </Col>
              <Col sm="4">
                <BrowingHostByCategory />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
