import React from "react";
import NavBar from "../Dashboard/DashboardNavbar";
import ReportsMain from "./ReportsMain";
import ls from "local-storage";

export default class ReportsLayout extends React.Component {
  render() {
    if (this.props.isToggleAttempted) {
      this.props.toggleAllowedCallback(true);
    }
    const isAuthenticated = ls.get("isAuthenticated");
    if (!isAuthenticated) {
      window.location.assign("/");
    }
    return (
      <div className="bm-website">
        <NavBar />
        <ReportsMain />
      </div>
    );
  }
}
