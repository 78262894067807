import React, { Component } from "react";
import * as API from "../../../utils/api";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { Spinner, PaginationItem, PaginationLink, Row, Col } from "reactstrap";
import * as Utils from "../../../utils/Utils";
import UltimatePaginationBootstrap4 from "react-ultimate-pagination-bootstrap-4";

export default class FilesPaginatedTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      dlpStatsArray: [],
      pageSize: 10,
      pagesCount: 0,
      hidden: true,
      totalResults: 0,
      gDrive: false,
      realTime: false,
      isLoading: true,
    };
    this.populateDlpStats = this.populateDlpStats.bind(this);
  }

  componentDidMount() {
    this.getFirstPageIncidents(0);
  }

  getFirstPageIncidents(currentPage) {
    if (this.props.gDrive === "true") {
      if (this.props.realTime === "true") {
        this.state.isLoading = true
        API.getGDriveEventsPaginated(currentPage, this.state.pageSize)
          .then((data) => {
            this.state.isLoading = false
            console.log(data);
            this.populateDlpStats(data);
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({ realTime: true });
      } else {
        this.state.isLoading = true
        API.getGDriveIncidentsPaginated(currentPage, this.state.pageSize)
          .then((data) => {
            this.state.isLoading = false
            console.log(data);
            this.populateDlpStats(data);
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({ gDrive: true });
      }
    } else {
      if (this.props.realTime === "true") {
        this.state.isLoading = true
        API.getFilesEventsPaginated(currentPage, this.state.pageSize)
          .then((data) => {
            this.state.isLoading = false
            console.log(data);
            this.populateDlpStats(data);
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({ realTime: true });
      } else {
        this.state.isLoading = true
        API.getFilesincidentsPaginated(currentPage, this.state.pageSize)
          .then((data) => {
            this.state.isLoading = false
            console.log(data);
            this.populateDlpStats(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  populateDlpStats(data) {
    let total = data.total;
    this.setState({ hidden: false });
    let dlpStatsValue = data.entityList.map((element, index) => {
      let username = "";
      if (!this.state.gDrive) {
        let user = element.username.split("\\");
        username = element.machineName + "/" + user[1];
      } else {
        username = element.username;
      }

      return {
        id: element.id,
        fileName: element.fileName,
        location: element.location,
        eventType: element.eventType,
        rulesViolated: element.infoType === null ? null : element.infoType.replace(new RegExp(",", "g"), ", "),
        machineName: element.machineName,
        createdOn: Utils.parseDate(element.createdOn),
        username: username,
        Severity: element.topSeverity,
        sharedWith: element.sharedList,
      };
    });
    this.setState({ totalResults: total });

    let totalPages = total / data.size;
    console.log("Total pages: " + totalPages)
    this.setState({ pagesCount: Math.ceil(totalPages) });

    this.setState({ dlpStatsArray: dlpStatsValue });
  }

  handleClick(index) {
    this.setState({
      currentPage: index,
    });
    this.getFirstPageIncidents(index);
  }

  renderList() {
    if (this.state.isLoading == true) {
      return (
        <div style={{ paddingLeft: "350px", paddingTop: "140px", paddingBottom: "120px" }}>
          <Spinner type="grow" color="primary" />
          <Spinner type="grow" color="secondary" />
          <Spinner type="grow" color="success" />
          <Spinner type="grow" color="danger" />
        </div>
      );
    } else {
      return (
        <Table hidden={this.state.hidden} striped responsive
          style={{ background: "white", fontSize: "10px", textAlign: "left" }}
          size="sm"
        >
          <thead style={{ color: "white", background: "grey" }}>
            <tr>
              <th
                hidden={!this.state.realTime}
                style={{ "white-space": "nowrap" }}
              >
                Event ID
            </th>
              <th
                hidden={this.state.realTime}
                style={{ "white-space": "nowrap" }}
              >
                Incident ID
            </th>
              <th
                hidden={!this.state.realTime}
                style={{ "white-space": "nowrap" }}
              >
                Event Time
            </th>
              <th
                hidden={this.state.realTime}
                style={{ "white-space": "nowrap" }}
              >
                Incident Time
              </th>
              <th hidden={!this.state.realTime} style={{ "white-space": "nowrap" }}>
                {" "}
                Event Type
              </th>
              <th style={{ "white-space": "nowrap" }}>File Owner</th>
              <th style={{ "white-space": "nowrap" }}>File Name</th>
              <th style={{ "white-space": "nowrap" }}>File Location</th>
              <th hidden={!this.state.gDrive} style={{ "white-space": "nowrap" }}>Shared With</th>
              <th style={{ "white-space": "nowrap" }}>Rules Violated</th>
              <th style={{ "white-space": "nowrap" }}>Severity</th>
            </tr>
          </thead>
          <tbody style={{ background: "white" }}>
            {this.state.dlpStatsArray.map((data, key) => {
              return (
                <tr key={key}>
                  <td style={{ "white-space": "nowrap" }}> {data.id}</td>
                  <td style={{ "white-space": "nowrap" }}>{data.createdOn}</td>
                  <td hidden={!this.state.realTime} style={{ "white-space": "nowrap" }}>
                    {data.eventType}
                  </td>
                  <td style={{ "white-space": "nowrap" }}>{data.username}</td>
                  <td style={{ "white-space": "nowrap" }}> {data.fileName}</td>
                  <td style={{ "white-space": "nowrap" }}>
                    {!data.location ? "/" : data.location}
                  </td>
                  <td hidden={!this.state.gDrive} style={{ "white-space": "nowrap" }}>
                    {data.sharedWith}
                  </td>
                  <td style={{ "white-space": "nowrap" }}>
                    {data.rulesViolated}
                  </td>
                  <td style={{ "white-space": "nowrap" }}>{data.Severity}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }
  }

  renderPagination() {
    const { currentPage, pagesCount } = this.state;
    if (pagesCount <= 1) {
      return <div />;
    }
    else {
      return (
        <UltimatePaginationBootstrap4
          currentPage={currentPage + 1}
          totalPages={pagesCount}
          onChange={(index) => {
            this.handleClick(index - 1);
          }}
        />
      );
    }
  }

  render() {
    let startIndex = this.state.currentPage * this.state.pageSize + 1;
    let toIndex =
      this.state.pageSize + this.state.pageSize * this.state.currentPage;
    if (toIndex > this.state.totalResults) {
      toIndex = this.state.totalResults;
    }
    return (
      <div>
        {this.renderList()}
        <Row>
          <Col sm="4">
            <p hidden={this.state.hidden} style={{ float: "left" }}>
              Showing {startIndex} to {toIndex} of {this.state.totalResults}{" "}
              results
            </p>
          </Col>
          <Col sm="8">{this.renderPagination()}</Col>
        </Row>
      </div>
    );
  }
}
