import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import OsDistribution from "./system/operatingSystem/OsDistribution";
import DisTopVulnerabilities from "./system/vulnerabilites/DisTopVulnerabilities";
import AntivirusDistribution from "./system/antivirus/AntivirusDistribution";
import AgentServiceStatus from "./system/agentService/AgentServiceStatus";

export default class DiscoverySystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabText: "Overview",
    };
  }

  render() {
    return (
      <div>
        <a id="vulnerabilties"></a>
        <Nav style={{ marginTop: "9px" }} card>
          <NavItem>
            <NavLink className="{classnames({ active: true })} bm-tab-header">
              Vulnerabilities
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab="1">
          <TabPane
            tabId="1"
            className="dashboard-tab-bg"
            style={styles.tabPane}
          >
            <DisTopVulnerabilities />
          </TabPane>
        </TabContent>

        <a id="service"></a>
        <Nav card>
          <NavItem>
            <NavLink className="{classnames({ active: true })} bm-tab-header">
              Service
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab="2">
          <TabPane
            tabId="2"
            className="dashboard-tab-bg"
            style={styles.tabPane}
          >
            <AgentServiceStatus chartId="agentServiceStatus" />
          </TabPane>
        </TabContent>

        <a id="av"></a>
        <Nav style={{ marginTop: "9px" }} card>
          <NavItem>
            <NavLink className="{classnames({ active: true })} bm-tab-header">
              Antivirus
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab="1">
          <TabPane
            tabId="1"
            className="dashboard-tab-bg"
            style={styles.tabPane}
          >
            <AntivirusDistribution chartId="antivirusDistribution" />
          </TabPane>
        </TabContent>
        
        <a id="os"></a>
        <Nav style={{ marginTop: "9px" }} card>
          <NavItem>
            <NavLink className="{classnames({ active: true })} bm-tab-header">
              Operating System
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab="2">
          <TabPane
            tabId="2"
            className="dashboard-tab-bg"
            style={styles.tabPane}
          >
            <OsDistribution chartId="osDistributionOverview" />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const styles = {
  tabPane: { paddingTop: "1px", paddingBottom: "1px" },
};
