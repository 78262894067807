import React from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import * as API from "../../utils/api";
import "../../components/Dashboard.css";
import * as CardStyles from "../../styles/CardStyles";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import * as Utils from "../../utils/Utils";
import Card from "react-bootstrap/Card";
import prettyBytes from "pretty-bytes";

export default class DataRestore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      machineBackupArray: {},
      selectedMachineName: {},
      selectedTimestamp: {},
      selectedBackupType: {},
      selectedTotalBytesBackedUp: {},
      selectedBackupTarget: {},
      selectedNumberOfFilesBackedUp: {},
      selected: 0,
      modal: false,
      unmountOnClose: true,
      dataLoaded: false,
    };
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeUnmountOnClose = this.changeUnmountOnClose.bind(this);
    this.toggle();
  }

  componentDidMount() {
    API.DataRestoreApi(0, 10)
      .then((data) => {
        let dlpStatsValue = data.entity.map((element, index) => {
          return {
            machineName: element.machineName,
            backupTarget: element.backupTarget,
            backupState: element.backupState,
            backupType: element.backupTarget.includes("full") ? "FULL" : "INCR",
            timestamp: Utils.parseDate(element.timestamp),
            totalBytesBackedUp: prettyBytes(element.totalBytesBackedUp),
            failedObjects: element.failedObjects,
            numberOfFilesBackedUp: element.numberOfFilesBackedUp.toLocaleString(),
          };
        });

        var machineBackupsMap = {};
        dlpStatsValue.map((element, index) => {
          var count = machineBackupsMap[element.machineName];
          if (count == null) {
            count = 0;
          }
          count = count + 1;
          machineBackupsMap[element.machineName] = count;
        });

        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ machineBackupArray: machineBackupsMap });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  changeUnmountOnClose(e) {
    let value = e.target.value;
    this.setState({ unmountOnClose: JSON.parse(value) });
  }

  handleRowClick(event) {
    this.setState({
      selectedMachineName: event.machineName,
      selectedBackupTarget: event.backupTarget,
      selectedNumberOfFilesBackedUp: event.numberOfFilesBackedUp,
      selectedTimestamp: event.timestamp,
      selectedBackupType: event.backupType,
      selectedTotalBytesBackedUp: event.totalBytesBackedUp,
      selected: event.totalBytesBackedUp,
    });
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  };

  handleRestore(e) {
    let data = this.state;
    e.preventDefault();
    this.setState({
      selectedMachineName: {},
      selectedBackupTarget: {},
      selectedNumberOfFilesBackedUp: {},
      selectedTimestamp: {},
      selectedBackupType: {},
      selectedTotalBytesBackedUp: {},
      selected: 0,
    });
    this.toggle();
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Data Restore
            </Card.Header>
            <Card.Body>
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (
      this.state.dlpStatsArray == null ||
      this.state.dlpStatsArray.length <= 0
    ) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Data Restore
            </Card.Header>
            <Card.Body>
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>Data Restore</Card.Header>
          <Card.Body>
            <Table
              striped
              responsive
              style={{ background: "white", fontSize: "12px" }}
              size="sm"
            >
              <thead
                style={{
                  color: "white",
                  background: "grey",
                  textAlign: "left",
                }}
              >
                <tr>
                  <th>Machine Name</th>
                  <th>Backup Location</th>
                  <th>Backup Status</th>
                  <th>Backup Type</th>
                  <th>Backup Date</th>
                  <th># of Files</th>
                  <th>File Size</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody style={{ background: "white", textAlign: "left" }}>
                {this.state.dlpStatsArray.map((data, key) => {
                  return (
                    <tr key={key} onClick={() => this.handleRowClick(data)}>
                      <td>{data.machineName}</td>
                      <td>{data.backupTarget}</td>
                      <td>{data.backupState}</td>
                      <td>{data.backupType}</td>
                      <td>{data.timestamp}</td>
                      <td>{data.numberOfFilesBackedUp}</td>
                      <td>{data.totalBytesBackedUp}</td>
                      <td>
                        <input
                          type="radio"
                          name="selectedRestore"
                          onChange={(e) => this.handleChange(e)}
                          checked={
                            this.state.selected === data.totalBytesBackedUp
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={this.props.className}
              unmountOnClose={this.state.unmountOnClose}
            >
              <ModalHeader toggle={this.toggle}>
                Restore Instructions
              </ModalHeader>
              <ModalBody>
                <p>
                  1. Please open Command Prompt (cmd) of this machine and run as
                  an administrator.
                </p>
                <p>
                  2. Switch directory to c:\Program Files
                  (x86)\bytemonk.co\ByteMonkSvc.
                </p>
                <p>
                  3. Run command ByteMonkCmd.exe -rbak &lt;srcLocation&gt;
                  &lt;targetLocation&gt; (Note: srcLocation is the source
                  directory where backups are stored and targetLocation is the
                  destination directory where you want the backups to be
                  restored)
                </p>
                <p>4. Press Enter to continue (do it twice).</p>
                <p>
                  5. For restoring the latest backup, key in the password as
                  communicated to you or as setup by you and press enter.
                </p>
              </ModalBody>
            </Modal>
            <Button
              style={{ float: "left" }}
              onClick={this.handleRestore}
              variant="primary"
            >
              Restore
            </Button>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
