import React from "react";
import { Spinner } from "reactstrap";
import * as API from "../../../utils/api";
import "../../Dashboard.css";
import * as CardStyles from "../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import BarChart3dVertical from "../../generic/BarChart3dVertical";

export default class DisTopDetections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      Column1Name: "User Name",
      Column2Name: "Current Usage",
      Column3Name: "Usage Limit",
      Column4Name: "",
      Column5Name: "",
      ReportTitle: "Top 5 Email Users",
      OrgusageLimit: "",
      OrgcurrentUsage: "",
      username: "",
      UserusageLimit: "",
      UsercurrentUsage: "",
      xAxis: "",
      graphArrayData: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.DiscoverTopDetections()
      .then((data) => {
        const dlpStatsValue = data.entity.map((element, index) => {
          return {
            yAxis1: element.sensitiveFileCount,
            xAxis: element.machineName,
          };
        });
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Top Detections
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Top Detections
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Top Detections
          </Card.Header>
          <Card.Body class="bm-card-body">
            <BarChart3dVertical
              data={this.state.dlpStatsArray}
              chartId="barChartTopDetections"
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}
