import React from "react";
import { Spinner } from "reactstrap";
import "../../components/Dashboard.css";
import * as CardStyles from "../../styles/CardStyles";
import Table from "react-bootstrap/Table";
import * as API from "../../utils/api";
import Card from "react-bootstrap/Card";

export default class UserGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersArray: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.userGroupsAPI()
      .then((data) => {
        let usersValue = data.entity.map((element) => {
          return {
            id: element.id,
            name: element.name,
            active: element.active === true ? "True" : "False",
          };
        });

        this.setState({ usersArray: usersValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>User Groups</Card.Header>
            <Card.Body  class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.state.usersArray && this.state.usersArray.length <= 0) {
      return (
        <div>
          <Card className="bm-center-card" style={{ minHeight: "300px" }}>
            <Card.Header style={CardStyles.cardHeader}>User Groups</Card.Header>
            <Card.Body  class="bm-card-body">
              <Spinner type="grow" size="sm" color="primary" />{" "}
              <Spinner type="grow" size="sm" color="secondary" />
              Loading.....
              <br />
              <br />
              <br />
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>User Groups</Card.Header>
          <Card.Body class="bm-card-body">
            <div style={{ margin: "5px" }}>
              <Table
                striped
                style={{
                  background: "white",
                  fontSize: "12px",
                  textAlign: "left",
                }}
                size="sm"
              >
                <thead style={{ color: "white", background: "grey" }}>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody style={{ background: "white" }}>
                  {this.state.usersArray.map((data, key) => {
                    return (
                      <tr key={data.id}>
                        <td>{data.id}</td>
                        <td>{data.name}</td>
                        <td>{data.active}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>{" "}
              <br />
              <br />
              <br />
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
