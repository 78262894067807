import React from "react";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "../../components/Dashboard.css";
import "../Monitor/monitor.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DataRestore from "../Backup/DataRestore";
import BackupOverview from "./overview/BackupOverview";

export default class BackupMain extends React.Component {
  constructor(props) {
    super(props);
    if(sessionStorage.getItem("backupTab")==null){
      sessionStorage.setItem("backupTab","1")
    }
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: sessionStorage.getItem("backupTab"),
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    sessionStorage.setItem("backupTab",selectedTab)
    this.setState({
      activeTab: selectedTab,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="dashboard-header">
          Data Backup and Restore
          <hr style={{ marginTop: "-1px" }}></hr>
        </div>
        <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect}>
          <Tab eventKey={1} title="Backup" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              Last 5 backup details
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1" className="dashboard-tab-bg">
                <Row>
                  <Col>
                    <BackupOverview />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>

          <Tab eventKey={2} title="Restore" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              Backup restore details
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="2" className="dashboard-tab-bg">
                <Row>
                  <Col>
                    <DataRestore />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
