import React from "react";
import * as API from "../../utils/api";
import "../../components/Dashboard.css";
import * as CardStyles from "../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Modal, Spinner } from "react-bootstrap";
import { Input, Form, Label, Row, Col } from "reactstrap";

export default class MonitorReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileDownload: true,
      emailDownload: false,
      networkUsageDownload:false,
      durationDays: 7,
      isLoading: false,
    };
    this.handleReportDownload = this.handleReportDownload.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleReportDownload(e) {
    e.preventDefault();
    if (this.state.emailDownload) {
      API.emailEventsReportDownload(this.state.durationDays).then(() => {this.setState({ isLoading: false })});
    }
    if (this.state.fileDownload) {
      API.fileEventsReportDownload(this.state.durationDays).then(() => {this.setState({ isLoading: false })});
    }
    if (this.state.networkUsageDownload) {
      API.networkReportDownload(this.state.durationDays).then(() => {this.setState({ isLoading: false })});
    }
    this.setState({isLoading: true});
  }

  handleChange = async (event) => {
    const { target } = event;
    if (target.id === "daysSelected7") {
      this.setState({ durationDays: 7 });
    }
    if (target.id === "daysSelected15") {
      this.setState({ durationDays: 15 });
    }
    if (target.id === "daysSelected30") {
      this.setState({ durationDays: 30 });
    }
    if (target.id === "fileSelected") {
      this.setState({ fileDownload: true });
      this.setState({ emailDownload: false });
      this.setState({ networkUsageDownload: false });
    }
    if (target.id === "emailSelected") {
      this.setState({ fileDownload: false });
      this.setState({ emailDownload: true });
      this.setState({ networkUsageDownload: false });
    }
    if (target.id === "networkSelected") {
      this.setState({ fileDownload: false });
      this.setState({ emailDownload: false });
      this.setState({ networkUsageDownload: true });
    }
  };

  render() {
    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Monitor Reports
          </Card.Header>
          <Card.Body class="bm-card-body">
            <Form
              className="form"
              style={{ fontSize: "14px", textAlign: "left" }}
              onSubmit={(e) => this.handleReportDownload(e)}
            >
              <Row>
                <Col sm="2">
                  <Label>Download Report:</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label check>
                    <Input type="radio" name="entitySelected" id="fileSelected" defaultChecked
                      onChange={(e) => this.handleChange(e)}
                    />{" "}
                    Files
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label check>
                    <Input type="radio" name="entitySelected" id="emailSelected"
                      onChange={(e) => this.handleChange(e)}
                    />{" "}
                    Emails
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label check>
                    <Input type="radio" name="entitySelected" id="networkSelected"
                      onChange={(e) => this.handleChange(e)}
                    />{" "}
                    Network
                  </Label>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm="2">
                  <Label>Duration:</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label>
                    <Input type="radio" name="daysSelected" id="daysSelected7" defaultChecked="true"
                      onChange={(e) => this.handleChange(e)}
                    />{" "}
                    Last 7 days
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label>
                    <Input
                      type="radio"
                      name="daysSelected"
                      id="daysSelected15"
                      onChange={(e) => this.handleChange(e)}
                    />{" "}
                    Last 15 days
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label>
                    <Input
                      type="radio"
                      name="daysSelected"
                      id="daysSelected30"
                      onChange={(e) => this.handleChange(e)}
                    />{" "}
                    Last 30 days
                  </Label>
                </Col>
              </Row>
              <br />
              <Button
                onClick={this.handleReportDownload}
                style={{ float: "left" }}
                variant="primary"
                type="submit"
              >
                Download{" "}
              </Button>
            </Form>
          </Card.Body>
        </Card>

        <Modal centered animation={false} show={this.state.isLoading} className={"loader-modal"}>
          <Spinner animation="border" style={{ color: "#38acec", width: "64px", height: "64px" }} />
        </Modal>
      </div>
    );
  }
}
