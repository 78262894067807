import React from "react";
import { Spinner, Row, Col } from "reactstrap";
import * as API from "../../../utils/api";
import * as Utils from "../../../utils/Utils";
import "../../../components/Dashboard.css";
import * as CardStyles from "../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import BarChart3dVertical from "../../generic/BarChart3dVertical";

export default class MonEmailStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      Column1Name: "User Name",
      Column2Name: "Current Usage",
      Column3Name: "Usage Limit",
      Column4Name: "",
      Column5Name: "",
      ReportTitle: "Top 5 Email Users",
      OrgusageLimit: "",
      OrgcurrentUsage: "",
      username: "",
      UserusageLimit: "",
      UsercurrentUsage: "",
      policyName: "",
      count: "",
      graphArrayData: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.MonitorEmailStats()
      .then((data) => {
        console.log(
          "In MonitorEmailStats API Call with data " + JSON.stringify(data)
        );

        const dlpStatsValue = {
          crossDomainsFrom: data.entity.crossDomainsFrom.map((it, index) => {
            return { xAxis: it.domainName, yAxis1: it.count };
          }),
          crossDomainsTo: data.entity.crossDomainsTo.map((it, index) => {
            return { xAxis: it.domainName, yAxis1: it.count };
          }),
          topReceivers: data.entity.topReceivers.map((it, index) => {
            return {
              xAxis: Utils.splitAndGetFirst(it.userName, 0, "@"),
              yAxis1: it.count,
            };
          }),
          topSenders: data.entity.topSenders.map((it, index) => {
            return {
              xAxis: Utils.splitAndGetFirst(it.userName, 0, "@"),
              yAxis1: it.count,
            };
          }),
          topDetections: data.entity.detectionsByUsernames.map((it, index) => {
            return {
              xAxis: Utils.splitAndGetFirst(it.username, 0, "@"),
              yAxis1: it.sensitiveCount,
            };
          }),
        };

        console.log(
          "In MonitorEmailStats API Call " + JSON.stringify(dlpStatsValue)
        );
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Row>
            <Col sm="6">
              <h4 className="bm-tab-heading">Destination Domain</h4>
              <Spinner type="grow" size="sm" color="primary" />{" "}
              <Spinner type="grow" size="sm" color="secondary" />
              Loading.....
            </Col>
            <Col sm="6">
              <h4 className="bm-tab-heading">Top Senders</h4>
              <Spinner type="grow" size="sm" color="primary" />{" "}
              <Spinner type="grow" size="sm" color="secondary" />
              Loading.....
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <h4 className="bm-tab-heading">Recipient Domain</h4>
              <Spinner type="grow" size="sm" color="primary" />{" "}
              <Spinner type="grow" size="sm" color="secondary" />
              Loading.....
            </Col>
            <Col sm="6">
              <h4 className="bm-tab-heading">Top Receivers</h4>
              <Spinner type="grow" size="sm" color="primary" />{" "}
              <Spinner type="grow" size="sm" color="secondary" />
              Loading.....
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <h4 className="bm-tab-heading">Top Detections</h4>
              <Spinner type="grow" size="sm" color="primary" />{" "}
              <Spinner type="grow" size="sm" color="secondary" />
              Loading.....
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div>
        <Row>
          <Col sm="4">
            <Card className="bm-center-card">
              <Card.Header style={CardStyles.cardHeader}>
                Destination Domain
              </Card.Header>
              <Card.Body class="bm-card-body">
                <BarChart3dVertical
                  data={this.state.dlpStatsArray.crossDomainsTo}
                  chartId="monEmailDestinationDomain"
                />
              </Card.Body>
            </Card>
          </Col>

          <Col sm="4">
            <Card className="bm-center-card">
              <Card.Header style={CardStyles.cardHeader}>
                Top Senders
              </Card.Header>
              <Card.Body class="bm-card-body">
                <BarChart3dVertical
                  data={this.state.dlpStatsArray.topSenders}
                  chartId="monEmailTopSenders"
                />
              </Card.Body>
            </Card>
          </Col>

          <Col sm="4">
            <Card className="bm-center-card">
              <Card.Header style={CardStyles.cardHeader}>
                Recipient Domain
              </Card.Header>
              <Card.Body class="bm-card-body">
                <BarChart3dVertical
                  data={this.state.dlpStatsArray.crossDomainsFrom}
                  chartId="monEmailRecipientDomain"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm="2"></Col>

          <Col sm="4">
            <Card className="bm-center-card">
              <Card.Header style={CardStyles.cardHeader}>
                Top Receivers
              </Card.Header>
              <Card.Body class="bm-card-body">
                <BarChart3dVertical
                  data={this.state.dlpStatsArray.topReceivers}
                  chartId="monEmailtopReceivers"
                />
              </Card.Body>
            </Card>
          </Col>

          <Col sm="4" style={{ paddingLeft: "18px" }}>
            <Card className="bm-center-card">
              <Card.Header style={CardStyles.cardHeader}>
                Top Detections
              </Card.Header>
              <Card.Body class="bm-card-body">
                <BarChart3dVertical
                  data={this.state.dlpStatsArray.topDetections}
                  chartId="monEmailTopDetections"
                />
              </Card.Body>
            </Card>
          </Col>

          <Col sm="2"></Col>
        </Row>
      </div>
    );
  }
}
