import React, { Component } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../Dashboard/Dashboard.css";

am4core.useTheme(am4themes_animated);

export default class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
    };
    this.createSeries = this.createSeries.bind(this);
  }

  componentDidMount() {
    var chart = am4core.create("linechartdiv", am4charts.XYChart);
    chart.data = this.props.data;
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.startLocation = 0.3;
    dateAxis.endLocation = 0.6;
    dateAxis.renderer.minGridDistance = 10;
    dateAxis.renderer.fontSize = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    this.createSeries(chart, "Total Files", "totalFileCount");
    this.createSeries(chart, "Sensitive Files", "sensitiveFileCount");

    // Add a legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.fontSize = 11;

    // axis ranges
    var range = dateAxis.axisRanges.create();
    range.date = new Date(2001, 1, 1);
    range.endDate = new Date(2003, 1, 1);
    range.axisFill.fill = chart.colors.getIndex(7);
    range.axisFill.fillOpacity = 0.2;

    var range2 = dateAxis.axisRanges.create();
    range2.date = new Date(2007, 1, 1);
    range2.grid.stroke = chart.colors.getIndex(7);
    range2.grid.strokeOpacity = 0.6;
    range2.grid.strokeDasharray = "5,2";
  }

  createSeries(chart, name, valueY) {
    var series = chart.series.push(new am4charts.LineSeries());
    series.name = name;
    series.dataFields.dateX = "createdOn";
    series.dataFields.valueY = valueY;
    series.tooltipText = "[#000]{valueY.value}[/]";
    series.tooltip.background.fill = am4core.color("#FFF");
    series.tooltip.getFillFromObject = false;
    series.tooltip.getStrokeFromObject = true;
    series.tooltip.background.strokeWidth = 3;
    series.sequencedInterpolation = true;
    series.fillOpacity = 0.6;
    series.stacked = true;
    series.strokeWidth = 2;
    series.tensionX = 0.77;
    return series;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id="linechartdiv"></div>;
  }
}
