import * as sha1 from "js-sha1";

export const parseDate = (input) => {
  var d = new Date(input);
  d.toLocaleString("en-US", { hour: "numeric", hour12: true });
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var amOrPm = d.getHours() < 12 ? "AM" : "PM";
  var hour = null;
  if (d.getHours() === 12) {
    hour = d.getHours();
  } else {
    hour = d.getHours() < 12 ? d.getHours() : d.getHours() - 12;
  }
  var minute = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  let formatted_date =
    d.getDate() +
    " " +
    months[d.getMonth()] +
    " " +
    d.getFullYear() +
    " " +
    hour +
    ":" +
    minute +
    " " +
    amOrPm;
  return formatted_date;
};

export const createSha1Hash = (data) => {
  var hash = sha1.array(data);
  var byte64EncodedHash = Buffer.from(hash).toString("base64");
  return byte64EncodedHash;
};

export const splitAndGetFirst = (data, index, delimter) => {
  if (data != null) {
    var arr = data.split(delimter);
    if (arr != null && arr.length > 0) {
      return arr[index];
    }
  }
  return null;
};
