import React from "react";
import { Spinner } from "reactstrap";
import * as API from "../../utils/api";
import "../../components/Dashboard.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ToggleableBoxComponent from "./ToggleableBoxComponent";
import Modal from "react-bootstrap/Modal";
import * as CardStyles from "../../styles/CardStyles";

export default class PolicyConfigsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editModeActive: false,
      dlpStatsArray: [],
      initialDlpStats: [],
    };
  }

  componentDidMount() {
    this.fetchDlpPolicies();
  }

  fetchDlpPolicies() {
    API.dlpPolicyConfigs()
      .then((data) => {
        const dlpStatsValue = data.entity;
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ initialDlpStats: getClone(dlpStatsValue) });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <h4 className="bm-tab-heading">Policy Management</h4>
          <Spinner type="grow" size="sm" color="primary" />{" "}
          <Spinner type="grow" size="sm" color="secondary" />
          Loading.....
        </div>
      );
    }
    if (this.props.isToggleAttempted) {
      if (this.state.editModeActive) {
        return (
          <Modal show={true}>
            <Modal.Header closeButton={false}>
              <Modal.Title>Are you sure you want to discard your changes?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Your recent configuration changes are not saved yet.
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "start" }}>
              <Button
                variant="primary"
                onClick={() => {
                  this.props.acceptToggle(true);
                }}
              >
                Yes
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  this.props.acceptToggle(false);
                  this.cancelEdit();
                }}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
        );
        this.props.acceptToggle(true);
      } else {
        this.props.acceptToggle(true);
      }
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader} className="bm-tab-heading">
            Policy Management
          </Card.Header>
          <Card.Body>
            {this.state.dlpStatsArray.map((data, key1) => {
              return (
                <div className="leftalign" key={data.label}>
                  <h6 className="policyName" id={"toggler" + key1}>
                    {data.label}
                  </h6>
                  <div className="row" style={{ marginTop: "20px" }}>
                    {data.tenantInfoTypes.map((data, key) => {
                      return (
                        <ToggleableBoxComponent
                          key={key}
                          id={data.label + "_" + key}
                          activated={data.active}
                          text={data.label}
                          showToggleSwitch={this.state.editModeActive}
                          onToggle={(event) => {
                            const currentDlpStatsArray = this.state
                              .dlpStatsArray;
                            currentDlpStatsArray[key1].tenantInfoTypes[
                              key
                            ].active = !currentDlpStatsArray[key1]
                              .tenantInfoTypes[key].active;
                            this.setState({
                              dlpStatsArray: currentDlpStatsArray,
                            });
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Card.Body>
        </Card>
        <Card style={{ marginBottom: "20px" }}>
          <Card.Body style={{ padding: "8px" }}>
            <div style={{ color: "#1E90FF", textAlign: "left" }}>
              {this.getCardButtons()}
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  getCardButtons() {
    if (this.state.editModeActive) {
      return (
        <div
          className="row"
          style={{ marginLeft: "12px", marginRight: "12px" }}
        >
          <Button
            className="col-sm-1"
            variant="primary"
            style={{ marginLeft: "8px", marginRight: "8px" }}
            onClick={() => {
              this.submitUpdatedPolicies();
            }}
          >
            Save
          </Button>
          <Button
            variant="secondary"
            className="col-sm-1"
            style={{ marginLeft: "8px", marginRight: "8px" }}
            onClick={() => {
              this.cancelEdit();
            }}
          >
          Cancel
          </Button>
        </div>
      );
    } else {
      return (
        <div
          className="row"
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Button
            className="col-sm-1"
            variant="primary"
            onClick={() => {
              this.setState({ editModeActive: true });
            }}
          >
            Edit
          </Button>
        </div>
      );
    }
  }

  cancelEdit() {
    this.setState({ dlpStatsArray: getClone(this.state.initialDlpStats) });
    this.setState({ editModeActive: false });
  }

  submitUpdatedPolicies() {
    API.updateDlpPolicyConfigs(this.state.dlpStatsArray)
      .then((data) => {
        console.log(JSON.stringify(data));
        this.setState({ dlpStatsArray: [] });
        this.fetchDlpPolicies();
        this.setState({ editModeActive: false });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }
}

const getClone = (object) => {
  return JSON.parse(JSON.stringify(object));
};

const styles = {
  activatedPolicies: {
    margin: "4px",
    background: "#0f7acc",
    height: "120px",
    borderColor: "#0f7acc",
  },
  deactivatedPolicies: {
    margin: "4px",
    background: "#6f7b85",
    height: "120px",
    borderColor: "#0f7acc",
  },
};
