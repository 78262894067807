import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../components/Dashboard.css";
import "../Monitor/monitor.css";
import MonFileIncidents from "../Monitor/Events/Mon-FileEvents";
import MonEmailIncidents from "../Monitor/Events/Mon-EmailEvents";
import MonEmailStats from "../Monitor/Events/Mon-EmailStats";
import MonNetworkUsageByDevice from "../Monitor/Events/Mon-NetworkUsageByDevice";
import MonBrowsingByHost from "../Monitor/Events/Mon-BrowsingByHost";
import MonNetworkTrafficAnalysis from "../Monitor/Events/Mon-NetworkTrafficAnalysis";
import MonUSBDeviceLog from "../Monitor/Events/Mon-USBDeviceLog";
import MonNetworkUsage from "../Dashboard/Summary/Data/Mon-NetworkUsage";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import * as ConfigsApi from "../../utils/api";

export default class MonitorMain extends React.Component {
  constructor(props) {
    if(sessionStorage.getItem("monitorTab")==null){
      sessionStorage.setItem("monitorTab","1")
      sessionStorage.setItem("monitorTabText", "Monitor: Files")
    }
    super(props);
    this.state = {
      activeTab: sessionStorage.getItem("monitorTab"),
      tabText: sessionStorage.getItem("monitorTabText"),
      networkingMonitoringEnabled: true,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    ConfigsApi.LicensePolicyConfigs()
      .then((data) => {
        const licensePolicies = data.entity.reduce(function (map, obj) {
          map[obj.policyName] = obj.enabled;
          return map;
        }, {});
        if (licensePolicies["NETWORK_MONITORING"]) {
          this.setState({ networkingMonitoringEnabled: true });
        } else {
          this.setState({ networkingMonitoringEnabled: false });
        }
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    let tabTextVal;
    if (selectedTab === "1") {
      tabTextVal = "Monitor: Files";
      sessionStorage.setItem("monitorTab","1")
      sessionStorage.setItem("monitorTabText", "Monitor: Files")
    }
    if (selectedTab === "2") {
      tabTextVal = "Monitor: Emails";
      sessionStorage.setItem("monitorTab","2")
      sessionStorage.setItem("monitorTabText", "Monitor: Emails")
    }
    if (selectedTab === "3") {
      if (this.state.networkingMonitoringEnabled) {
        tabTextVal = "Monitor: Network";
        sessionStorage.setItem("monitorTab","3")
        sessionStorage.setItem("monitorTabText", "Monitor: Network")
      } else {
        return;
      }
    }
    if (selectedTab === "4") {
      tabTextVal = "Monitor: Device";
      sessionStorage.setItem("monitorTab","4")
      sessionStorage.setItem("monitorTabText", "Monitor: Device")
    }

    this.setState({
      activeTab: selectedTab,
      tabText: tabTextVal,
    });
  }

  render() {
    return (
      <div>
        <div className="dashboard-header">
          {this.state.tabText}
          <hr style={{ marginTop: "-1px" }}></hr>
        </div>
        <Tabs
          card="true"
          activeKey={this.state.activeTab}
          onSelect={this.handleSelect}
        >
          <Tab eventKey={1} title="Files" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              Top File events
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <MonFileIncidents />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>

          <Tab eventKey={2} title="Emails" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              Top Email events
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="2" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <MonEmailIncidents />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <MonEmailStats />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>

          <Tab
            eventKey={3}
            title="Network"
            tabClassName={
              this.state.networkingMonitoringEnabled
                ? "bm-inactive-tab"
                : "bm-deactivated-tab"
            }
          >
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              Network data usage - Activity for the last 7 days
            </div>
            {this.getNetworkingTab(this.state.networkingMonitoringEnabled)}
          </Tab>

          <Tab eventKey={4} title="Device" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              USB Device Control Monitoring
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="4" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <MonUSBDeviceLog />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>
        </Tabs>
      </div>
    );
  }

  getNetworkingTab(isActive) {
    if (isActive) {
      return (
        <div>
          <Nav card>
            <NavItem>
              <NavLink
                className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Overview
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="3" className="dashboard-tab-bg">
              <Row>
                <Col sm="4">
                  <MonNetworkUsageByDevice />
                </Col>
                <Col sm="4">
                  <MonNetworkUsage />
                </Col>
                <Col sm="4">
                  <MonBrowsingByHost />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <MonNetworkTrafficAnalysis />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      );
    } else {
      return (
        <TabContent activeTab={this.state.activeTab}>
          <h6>This feature has been deactivated</h6>
        </TabContent>
      );
    }
  }
}
