import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "reactstrap";
import "./Dashboard.css";
import NewLogo16 from "../../images/NewLogo16.png";
import personLogo from "../../images/person.png";
import logoutLogo from "../../images/logout.png";
import ls from "local-storage";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { IndexLinkContainer, LinkContainer } from "react-router-bootstrap";
import * as API from "../../utils/api";

export default class DashboardNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      loginOpen: false,
      logoutOpen: false,
      userName: "",
      isAutheticated: false,
      isOpen: false,
      activeKey: "/",
      licensePolicies: {},
    };
    this.toggleLogin = this.toggleLogin.bind(this);
    this.toggleLogout = this.toggleLogout.bind(this);
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  componentDidMount() {
    var userNameVal = ls.get("name");
    if (userNameVal != null) {
      this.setState({
        userName: "Welcome " + userNameVal,
        isAutheticated: true,
      });
    }
    this.setState({
      activeKey: this.state.activeKey,
    });

    API.LicensePolicyConfigs()
      .then((data) => {
        const licensePolicies = data.entity.reduce(function (map, obj) {
          map[obj.policyName] = obj.enabled;
          return map;
        }, {});
        this.setState({ licensePolicies: licensePolicies });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  toggleLogin() {
    this.setState({
      loginOpen: !this.state.loginOpen,
    });
  }

  toggleLogout() {
    this.setState({
      logoutOpen: !this.state.logoutOpen,
    });
  }

  logout() {
    ls.clear();
    this.setState({
      userName: "",
      isAutheticated: false,
    });
    window.location.href = "/";
  }

  login() {
    ls.clear();
    window.location.href = "/login";
  }

  handleSelect(eventKey, e) {
    this.setState({
      activeKey: eventKey,
    });
  }

  render() {
    let activeKey = this.state.activeKey;
    let licensePolicies = this.state.licensePolicies;
    return (
      <div>
        <br></br>
        <div
          className="bm-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a href="/" style={{ marginLeft: "16px" }}>
            <img src={NewLogo16} alt="bytemonk_logo" className="bm-logo" />
          </a>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="logged-in-label" style={{ marginRight: "8px" }}>
              {this.state.userName}{" "}
            </div>
            <Link
              to="#"
              id="logoutToolTip"
              onClick={this.logout}
              hidden={!this.state.isAutheticated}
            >
              <img
                src={logoutLogo}
                alt="logout_logo"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "8px",
                  height: "15px",
                }}
              />
            </Link>
            <Link
              to="#"
              id="loginToolTip"
              onClick={this.login}
              hidden={this.state.isAutheticated}
              style={{ padding: "5px 0px" }}
            >
              <img
                src={personLogo}
                alt="person_logo"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "8px",
                  height: "15px",
                }}
              />
            </Link>
            <Tooltip
              style={{ position: "absolute", right: 8 }}
              placement="left-end"
              isOpen={this.state.logoutOpen}
              target="logoutToolTip"
              toggle={this.toggleLogout}
            >
              Logout!
            </Tooltip>
            <Tooltip
              style={{ position: "absolute", right: 8 }}
              placement="left-end"
              isOpen={this.state.loginOpen}
              target="loginToolTip"
              toggle={this.toggleLogin}
            >
              Login!
            </Tooltip>
          </div>
        </div>
        {(
          this.props.showNavBar !== null && this.props.showNavBar !== undefined
            ? this.props.showNavBar
            : true
        ) ? (
          <div>
            <Navbar
              collapseOnSelect
              expand="lg"
              className="bm-navbar"
              variant="dark"
            >
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav
                  className="mr-auto"
                  activeKey={activeKey}
                  onSelect={this.handleSelect}
                >
                  <LinkContainer style={styles.linkContainer} to="/dashboard">
                    <NavItem>DASHBOARD</NavItem>
                  </LinkContainer>
                  <LinkContainer style={styles.linkContainer} to="/monitor">
                    <NavItem>MONITOR</NavItem>
                  </LinkContainer>
                  <LinkContainer style={styles.linkContainer} to="/discover">
                    <NavItem>DISCOVER</NavItem>
                  </LinkContainer>
                  <LinkContainer style={styles.linkContainer} to="/query">
                    <NavItem>SEARCH</NavItem>
                  </LinkContainer>
                  <LinkContainer style={styles.linkContainer} to="/reports">
                    <NavItem>REPORTS</NavItem>
                  </LinkContainer>
                  <LinkContainer style={styles.linkContainer} to="/users">
                    <NavItem>USERS</NavItem>
                  </LinkContainer>
                  {this.getBackupLinkContainer(
                    licensePolicies["AUTOMATED_BACKUP"]
                  )}
                  <LinkContainer
                    style={styles.linkContainer}
                    to="/configurations"
                  >
                    <NavItem>CONFIGURATIONS</NavItem>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }

  getBackupLinkContainer(backupActive) {
    if (backupActive) {
      return (
        <LinkContainer style={styles.linkContainer} to="/backup">
          <NavItem>BACKUP</NavItem>
        </LinkContainer>
      );
    } else if (backupActive == false) {
      return (
        <LinkContainer style={styles.linkContainer} to="/">
          <NavItem>BACKUP</NavItem>
        </LinkContainer>
      );
    }
    else {
      return (
        <LinkContainer style={{ pointerEvents:'none',...styles.linkContainer }} to="/backup">
          <NavItem>BACKUP</NavItem>
        </LinkContainer>
      );
    }
  }
}

const styles = {
  linkContainer: { marginRight: "20px", fontSize: "14px" },
};
