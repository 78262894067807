import React from "react";
import "../../components/Dashboard.css";
import * as CardStyles from "../../styles/CardStyles";
import Table from "react-bootstrap/Table";
import * as API from "../../utils/api";
import * as Utils from "../../utils/Utils";
import { Modal, Spinner } from "react-bootstrap";

import { Input, CustomInput, Label, Form, FormGroup, Col, Row } from "reactstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

export default class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchResultsArray: [],
      filesSelected: true,
      gdriveSelected: false,
      txtFileName: "",
      machineSelected: false,
      allMachinesSelected: true,
      txtMachineName: "",
      resultFoundText: "",
      fileResultsFound: false,
      uploadFileContentHash: "",
      allMachineChecked: true,
      inputKey: "123",
      fileInputLabel: "Upload file",
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeResultTextStyle = this.changeResultTextStyle.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(event) {
    var file = event.target.files[0];
    this.setState({
      fileInputLabel: file.name,
    });
    var reader = new FileReader();
    const scope = this;
    reader.onload = function (event) {
      console.log(event.target.result);
      var byte64EncodedHash = Utils.createSha1Hash(event.target.result);
      console.log(byte64EncodedHash);
      scope.setState({ uploadFileContentHash: byte64EncodedHash });
    };
    try {
      reader.readAsText(file);
    } catch (Exception) {}
    this.handleChange(event);
  }

  handleClear(event) {
    let randomString = Math.random().toString(36);
    this.setState({
      ["txtFileName"]: "",
      ["txtMachineName"]: "",
      inputKey: randomString,
      fileInputLabel: "Upload file",
    });

    this.setState({ resultFoundText: "" });
    this.setState({ fileResultsFound: false });
  }

  handleSearch(event) {
    if (this.state.filesSelected === false && this.state.gdriveSelected === false) return;
    if (this.state.txtFileName === "" && this.state.uploadFileContentHash === "")  return;

    let gdrive = false;
    let filesSelected = false;
    if (this.state.filesSelected) filesSelected = true;
    if (this.state.gdriveSelected) gdrive = true;

    if (filesSelected || gdrive) {
      API.searchFilesApi(gdrive, this.state.txtFileName, this.state.uploadFileContentHash, this.state.machineSelected !== "false" ? this.state.txtMachineName : "")
        .then((data) => {
          const dlpStatsValue = data.map((element) => {
            return {
              fileName: element.fileName,
              fileType: element.mimeType,
              machineName: element.machineName,
              location: element.location,
              fileOwner: element.username,
              policyNames: element.policyNames,
              infoTypes: element.infoTypes,
              matchedTexts: element.matchedTexts,
              lastModifiedDate: Utils.parseDate(element.createdOn),
            };
          });

          this.setState({ searchResultsArray: dlpStatsValue });
          if (dlpStatsValue.length > 0) {
            this.setState({ resultFoundText: "Found following results:" });
            this.setState({ fileResultsFound: true });
          } else {
            this.setState({
              resultFoundText:
                "No results found. Ensure the File Name you are searching is correct.",
            });
            this.setState({ fileResultsFound: false });
          }
          this.setState({ isLoading: false });
        })
        .catch((e) => {
          console.log("Error in file search-> api fetch " + e);
          this.setState({ isLoading: false });
        });
      this.setState({ isLoading: true });
    }
  }

  handleChange = async (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const resetFileName = target.type === "file" ? true : false;
    const resetFileUpoad = target.type === "text" && target.id === "txtFileName" ? true : false;
    const name = target.name;
    console.log(name + " " + value);

    await this.setState({
      [name]: value ? value : "",
    });
    console.log(this.state.machineSelected);
    if (resetFileName) {
      await this.setState({
        ["txtFileName"]: "",
      });
    }
    let randomString = Math.random().toString(36);
    if (resetFileUpoad) {
      this.setState({
        inputKey: randomString,
        fileInputLabel: "Upload file",
      });
    }
  };

  changeResultTextStyle = function () {
    const color = this.state.fileResultsFound ? "black" : "red";
    const style = this.state.fileResultsFound ? "normal" : "italic";

    return {
      textAlign: "left",
      color: color,
      fontStyle: style,
    };
  };

  render() {
    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>Search Files</Card.Header>
          <Card.Body>
            <Form className="form" style={{ fontSize: "14px" }}
            onSubmit={(e) => {
              e.preventDefault();
              this.handleSearch(e);
            }}
            >
              <FormGroup style={{ margin: "10px", textAlign: "left" }}>
                <Row>
                  <Col sm="2">
                    <Label>File Name:</Label>
                  </Col>
                  <Col sm="4">
                    <Input style={{ fontSize: "12px" }} value={this.state.txtFileName} type="text" name="txtFileName" 
                      id="txtFileName"
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Col>
                  <Col
                    sm="5"
                    style={{ fontSize: "11px", fontStyle: "italic", marginTop: "8px", }}
                  >
                    <Label column>Enter the name of file for search</Label>
                  </Col>
                </Row>
                <br />
                OR <br /> <br />
                <Row>
                  <Col sm="2">
                    <Label column>File Content:</Label>
                  </Col>
                  <Col sm="4">
                    <CustomInput id="fileNameInput" style={{ fontSize: "12px" }} type="file" name="uploadFile" label={this.state.fileInputLabel}
                      key={this.state.inputKey}
                      onChange={(e) => this.uploadFile(e)}
                    />
                  </Col>
                  <Col
                    sm="5"
                    style={{ marginTop: "8px", fontSize: "11px", fontStyle: "italic", }}
                  >
                    <Label column>
                      Upload a file to find out all matches on the content of an uploaded file
                    </Label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm="2">Sources:</Col>
                </Row>
                <Row>
                  <Col sm="1">
                    <Input
                      style={{ margin: "2px", marginTop: "5px", fontSize: "12px", }} type="checkbox" defaultChecked
                      name="filesSelected" id="filesSelected"
                      onChange={(e) => this.handleChange(e)}
                    />
                    <Label style={{ marginLeft: "20px" }} column>
                      Files
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="1">
                    <Input
                      style={{ margin: "2px", marginTop: "5px", fontSize: "12px", }} type="checkbox" 
                      name="gdriveSelected" id="gdriveSelected"
                      onChange={(e) => this.handleChange(e)}
                    />
                    <Label style={{ marginLeft: "20px" }} column>
                      Drive
                    </Label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm="2">Restrict to:</Col>
                </Row>
                <FormGroup check>
                  <Row>
                    <Col sm="2">
                      <Label check>
                        <Input type="radio" name="machineSelected" id="machineSelected"
                          onChange={(e) => this.handleChange(e)}
                        />{" "}
                        Machine Name:
                      </Label>
                    </Col>
                    <Col sm="4">
                      <Input style={{ marginLeft: "-12px", fontSize: "10px" }} type="text" name="txtMachineName" id="txtMachineName"
                        value={this.state.txtMachineName}
                        checked={!this.state.allMachineChecked}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="machineSelected" id="machineSelected" value={false} defaultChecked
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />{" "}
                    All Machines
                  </Label>
                </FormGroup>
              </FormGroup>
              <Button style={{ margin: "5px", float: "left" }} color="primary" type="submit">
                Search
              </Button>
              <Button variant="secondary" style={{ margin: "5px", float: "left" }} color="secondary" onClick={this.handleClear}>
                Clear
              </Button>
            </Form>
            <br />
            <br />
            <div hidden={this.state.isLoading}>
              <p style={this.changeResultTextStyle()}>
                {" "}
                {this.state.resultFoundText}
              </p>
              <Table striped responsive style={{ background: "white", fontSize: "10px" }} size="sm" hidden={!this.state.fileResultsFound}>
                <thead style={{ color: "white", background: "grey", textAlign: "left", }}>
                  <tr>
                    <th>File Name</th>
                    <th>File Type</th>
                    <th>Machine Name</th>
                    <th>Location</th>
                    <th>File Owner</th>
                    <th>Last Modified Date</th>
                    <th>Matched Policy</th>
                    <th>Rules Violated</th>
                    <th>Matched Text</th>
                  </tr>
                </thead>
                <tbody
                  style={{ background: "white", textAlign: "left", }}
                >
                  {this.state.searchResultsArray.map(data => {
                    return (
                      <tr key={data.location + data.fileName}>
                        <td>{data.fileName}</td>
                        <td>{data.fileType}</td>
                        <td>{data.machineName}</td>
                        <td>{data.location}</td>
                        <td>{data.fileOwner}</td>
                        <td>{data.lastModifiedDate}</td>
                        <td>{data.policyNames}</td>
                        <td>{data.infoTypes}</td>
                        <td>{data.matchedTexts}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <Modal centered animation={false} show={this.state.isLoading} className={"loader-modal"}>
              <Spinner animation="border" style={{ color: "#38acec", width: "64px", height: "64px" }} />
            </Modal>
            
            
          </Card.Body>
        </Card>
      </div>
    );
  }
}
