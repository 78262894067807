import React from "react";
import { Spinner } from "reactstrap";
import * as API from "../../../../utils/api";
import "../../Dashboard.css";
import * as CardStyles from "../../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import LineChart from "../../../generic/LineChart";

export default class DisPolicyVoilationTrends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.DashboardDiscoverPolicyVoilationTrends()
      .then((data) => {
        const dlpStatsValue = data.entity.map((element, index) => {
          return {
            sensitiveFileCount: element.sensitiveFileCount,
            totalFileCount: element.totalFileCount,
            createdOn: element.createdOn,
          };
        });
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log(
          "Error in PolicyViolationsTrends Component Mount -> api fetch " + e
        );
      });
  }
  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Policy Violations Trends
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Policy Violations Trends
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Policy Violations Trends
          </Card.Header>
          <Card.Body class="bm-card-body">
            <LineChart data={this.state.dlpStatsArray} />
          </Card.Body>
        </Card>
      </div>
    );
  }
}
