import React from "react";
import { Spinner } from "reactstrap";
import * as API from "../../utils/api";
import "../../components/Dashboard.css";
import Card from "react-bootstrap/Card";
import * as CardStyles from "../../styles/CardStyles";

export default class LicenseConfigsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
    };
  }

  componentDidMount() {
    API.LicensePolicyConfigs()
      .then((data) => {
        const dlpStatsValue = data.entity.map((element, index) => {
          return {
            policyName: element.policyDescription,
          };
        });
        this.setState({ dlpStatsArray: dlpStatsValue });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }
  render() {
    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <h4 className="bm-tab-heading">License Management</h4>
          <Spinner type="grow" size="sm" color="primary" />{" "}
          <Spinner type="grow" size="sm" color="secondary" />
          Loading.....
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            License Management
          </Card.Header>
          <Card.Body>
            <h6 className="policyName">Platinum Access</h6>
            <div className="row" style={{ marginTop: "15px" }}>
              {this.state.dlpStatsArray.map((data, key) => {
                return (
                  <div className="col-sm-2" key={key}>
                    <Card
                      style={{
                        background: "#0f7acc",
                        height: "100px",
                        borderColor: "#0f7acc",
                      }}
                      className="bm-top5email-data"
                    >
                      <Card.Body
                        style={{
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Card.Text
                          style={{ textAlign: "center", fontSize: "16px" }}
                        >
                          {data.policyName}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
