import axios from "axios";
import ls from "local-storage";
import apiConstants from "./apiConstants";

const api = apiConstants.api;

let orgIdGuid = ls.get("orgIdGuid");
let jwt = ls.get("jwt");
let myheaders = {
  X_BM_REQUEST_AGENT_ID: "agent-123",
  X_BM_REQUEST_ORG_ID: orgIdGuid,
  "Authorization": `Bearer ${jwt}`,
};

const getAuthenticatedAxiosInstance = () => {
  let authenticatedAxiosInstance = axios.create({
    baseURL: `${apiConstants.api}/api/v1/`,
    headers: {
      X_BM_REQUEST_AGENT_ID: "agent-123",
      X_BM_REQUEST_ORG_ID: orgIdGuid,
      "Authorization": `Bearer ${jwt}`,
    },
  });
  authenticatedAxiosInstance.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response.status == axios.HttpStatusCode.Unauthorized) {
      ls.clear();
      if (window.location.href === "/") {
        window.location.href = "/";
      }
      return;
    }
    return Promise.reject(error);
  });
  return authenticatedAxiosInstance;
}

export const DlpStatsByFile = () => getAuthenticatedAxiosInstance().get("dlpStatsByFile").then(response => response.data)

export const DlpStatsByFileType = () => getAuthenticatedAxiosInstance().get("dlpStatsByFileType").then(response => response.data)

export const DlpStatsByMachineType = () => getAuthenticatedAxiosInstance().get("dlpStatsByMachineType").then(response => response.data)

export const DlpStatsByPolicyType = () => getAuthenticatedAxiosInstance().get("dlpStatsByPolicyType").then(response => response.data)

export const DlpStatsByRuleType = () => getAuthenticatedAxiosInstance().get("dlpStatsByRuleType").then(response => response.data)

export const DlpStatsForEmailUser = () => getAuthenticatedAxiosInstance().get("dlpStatsForEmailUser").then(response => response.data)

export const DlpStatsForTenantEmail = () => getAuthenticatedAxiosInstance().get("dlpStatsForTenantEmail").then(response => response.data)

export const DashboardOverviewHealthSummary = () => getAuthenticatedAxiosInstance().get("overview/healthSummary").then(response => response.data)

export const DashboardOverviewgSuiteSummary = () => getAuthenticatedAxiosInstance().get("overview/gSuiteSummary").then(response => response.data)

export const DashboardDiscoverPolicyVoilationTrends = () => getAuthenticatedAxiosInstance().get("discover/policyViolationTrends").then(response => response.data)

export const DashboardDiscoverPolicyVoilationBySeverity = () => getAuthenticatedAxiosInstance().get("discover/policyViolationBySeverity").then(response => response.data)

export const DashboardDiscoverPolicyVoilationByFileType = () => getAuthenticatedAxiosInstance().get("discover/policyViolationByFileType").then(response => response.data)

export const DashboardMonitorPolicyVoilationBySeverity = () => getAuthenticatedAxiosInstance().get("monitor/DataRiskEventsBySeverity?durationInDays=7").then(response => response.data)

export const DiscoverFileEvents = () => getAuthenticatedAxiosInstance().get("discover/fileEvents?durationInDays=7").then(response => response.data)

export const DiscoverGDriveEvents = () =>getAuthenticatedAxiosInstance().get("discover/gdriveEvents?durationInDays=7").then(response => response.data)

export const DiscoverTopVulnerabilities = () => getAuthenticatedAxiosInstance().get("discover/topVulnerabilities?durationInDays=7").then(response => response.data)

export const VulnerabilityDetails = () => getAuthenticatedAxiosInstance().get("discover/vulnerabilityDetails?durationInDays=7").then(response => response.data)

export const DiscoverTopDetections = () => getAuthenticatedAxiosInstance().get("discover/policyViolationByMachineName?durationInDays=7").then(response => response.data)

export const UserLoginApi = (email, password) =>
  axios.post("users/login", {
    email: email,
    passwordHash: password,
  }, {
    baseURL: `${api}/api/v1/`,
    headers: {
      X_BM_REQUEST_AGENT_ID: "agent-123",
      X_BM_REQUEST_ORG_ID: orgIdGuid,
      "Content-Type": "application/json",
      "mode": "cors",
    },
  }).then(response => {
    console.log("User Login Response", response);
    return response.data;
  }).catch(error => {
    Promise.reject(error);
  })

export const UserAuthenticateApi = (email, password) =>
  axios.post("users/authenticate", {
    username: email,
    password: password,
  }, {
    baseURL: `${api}/api/v1/`,
    headers: {
      X_BM_REQUEST_AGENT_ID: "agent-123",
      X_BM_REQUEST_ORG_ID: orgIdGuid,
      "Content-Type": "application/json",
    },
  }).then(response => {
    console.log(response);
    return response.data;
  }).catch(error => {
    console.log(error);
    Promise.reject(error);
  })

export const ResetPasswordApi = (email) =>
  getAuthenticatedAxiosInstance().post("users/resetPassword", {
    email: email,
  }).then(response => {
    return response.data;
  })

export const SubmitResetTokenApi = (email, resetToken, newPassword) =>
  axios.put(`users/password?reset-token=${resetToken}`, {
    email: email,
    passwordSalt: newPassword,
  }).then(response => {
    return response.data;
  }).catch(error => {
    Promise.reject(error);
  })

export const MonitorFileEvents = () => getAuthenticatedAxiosInstance().get("monitor/fileEvents?durationInDays=7").then(response => response.data)

export const MonitorGDriveEvents = () => getAuthenticatedAxiosInstance().get("monitor/gdriveEvents?durationInDays=7").then(response => response.data)

export const MonitorEmailEvents = () => getAuthenticatedAxiosInstance().get("monitor/emailEvents?durationInDays=7").then(response => response.data)

export const GetEmailEventsById = (eventId) => getAuthenticatedAxiosInstance().get(`reports/emailEvents/${eventId}?durationInDays=7`, {
    responseType: "blob"
  }).then((response) => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    document.body.appendChild(a);
    a.download = "email_event_" + eventId + ".csv";
    a.click();
    a.remove();
  })

export const MonitorEmailStats = () => getAuthenticatedAxiosInstance().get("monitor/emailStats").then(response => response.data)

export const dataBackupSummaryApi = () => getAuthenticatedAxiosInstance().get("backup/stats?limit=10").then(response => response.data)

export const getFilesincidentsPaginated = (pageNumber, pageSize) => getAuthenticatedAxiosInstance().get(`discover/fileIncidentList?durationInDays=7&page=${pageNumber}&size=${pageSize}`).then(response => response.data)

export const getFilesEventsPaginated = (pageNumber, pageSize) => getAuthenticatedAxiosInstance().get(`monitor/fileEventList?durationInDays=7&page=${pageNumber}&size=${pageSize}`).then(response => response.data)

export const getGDriveEventsPaginated = (pageNumber, pageSize) => getAuthenticatedAxiosInstance().get(`monitor/gDriveEventList?durationInDays=7&page=${pageNumber}&size=${pageSize}`).then(response => response.data)

export const getGDriveIncidentsPaginated = (pageNumber, pageSize) => getAuthenticatedAxiosInstance().get(`discover/gDriveIncidentList?durationInDays=7&page=${pageNumber}&size=${pageSize}`).then(response => response.data)

export const getEmailEventsPaginated = (pageNumber, pageSize) => getAuthenticatedAxiosInstance().get(`monitor/emailEventList?durationInDays=7&page=${pageNumber}&size=${pageSize}`).then(response => response.data)

export const getRiskyUsers = (limit) => getAuthenticatedAxiosInstance().get(`userRisk/riskyUsers?limit=${limit}`).then(response => response.data)

export const userGroupsAPI = () => getAuthenticatedAxiosInstance().get(`users/groups`).then(response => response.data)

export const userGetAPI = () => getAuthenticatedAxiosInstance().get(`users`).then(response => response.data)

export const userAddApi = (firstname, lastname, email, passwordHash) => getAuthenticatedAxiosInstance().post("users", [
    {
      active: true,
      email: email,
      firstName: firstname,
      lastName: lastname,
      orgIdGuid: "4fe26103-50a6-4e2a-9bda-24c4ed4149cb",
      passwordHash: passwordHash,
    },
  ]).then(response => response.data)

export const updateUserApi = (email, passwordHash, scanEnabled, groupId) => getAuthenticatedAxiosInstance().put("users", {
    email: email,
    groupId: groupId,
    scanEnabled: scanEnabled,
    passwordHash: passwordHash,
  }).then(response => response.data)

export const DataRestoreApi = (page, size) => getAuthenticatedAxiosInstance().get(`backup?page=${page}&size=${size}`).then(response => response.data)

export const configurationPolicyGetAPI = () => getAuthenticatedAxiosInstance().get(`policyConfigs/configurationPolicy`).then(response => response.data)

export const updateConfigurationPolicy = (configurationPolicy) => getAuthenticatedAxiosInstance().put("policyConfigs/configurationPolicy", configurationPolicy).then(response => response.data)

export const provisioningGetAPI = () => getAuthenticatedAxiosInstance().get(`provisioning?connectorType=O365`).then(response => response.data)

export const oauthRedirectUrlAPI = () => getAuthenticatedAxiosInstance().get(`oauth/google/url?redirectUrl=prod`).then(response => response.data)

export const getO365AdminConsentUrl = () => getAuthenticatedAxiosInstance().get(`login/admin_consent_url`).then(response => response.data)

export const dlpPolicyConfigs = () => getAuthenticatedAxiosInstance().get(`monitor/policyConfigs`).then(response => response.data)

export const updateDlpPolicyConfigs = (dlpPolicies) => getAuthenticatedAxiosInstance().put(`policyConfigs/dlpPolicy`, dlpPolicies).then(response => response.data)

export const LicensePolicyConfigs = () => getAuthenticatedAxiosInstance().get(`policyConfigs/licensePolicy`).then(response => response.data)

export const downloadAppControlCsv = (listName, sample) => getAuthenticatedAxiosInstance().get(`policyConfigs/appControlConfig/csvDownload/${listName}?sample=${sample}`, {
    responseType: "blob"
  }).then(response => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    document.body.appendChild(a);
    a.download = `${listName}.csv`;
    a.click();
    a.remove();
})

export const updateAppControlConfigBulk = (appControlMode, domainListFile, desktopAppsListFile, machineExclusionListFile) => {
  const formData = new FormData();
  formData.append("appControlMode", appControlMode);
  formData.append("urlList", domainListFile);
  formData.append("desktopAppsList", desktopAppsListFile);
  formData.append("machineExclusionList", machineExclusionListFile);
  return getAuthenticatedAxiosInstance().put("policyConfigs/appControlConfigBulk", formData).then(response => response.data);
}

export const updateAppControlConfig = appControlConfig => getAuthenticatedAxiosInstance().put("policyConfigs/appControlConfig", appControlConfig).then(response => response.data)

export const getAppControlConfig = () => getAuthenticatedAxiosInstance().get("policyConfigs/appControlConfig").then(response => response.data)

export const eventLogApi = (eventType) => getAuthenticatedAxiosInstance().get(`events?eventType= ${eventType}&duration=7`).then(response => response.data)

export const usbEventsReportDownload = (eventId, durationInDays) => getAuthenticatedAxiosInstance().get(eventId == null ? `events/usbEvents?duration=${durationInDays}&eventType=USB` : `events/usbEvents/${eventId}?duration=${durationInDays}&eventType=USB`, {
  responseType: "blob"
}).then(response => {
    let filename = eventId == null ? "usb_events.csv" : "usb_events_" + eventId + ".csv";
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    document.body.appendChild(a);
    a.download = filename;
    a.click();
    a.remove();
  });

export const networkUsageByDeviceApi = () => getAuthenticatedAxiosInstance().get("networkStats/networkUsageByDevice?durationInDays=7").then(response => response.data)

export const browseTimeByHostCategoryApi = () => getAuthenticatedAxiosInstance().get("networkStats/browseTimeByHostCategory?durationInDays=7").then(response => response.data)

export const browseTimeByHostApi = () => getAuthenticatedAxiosInstance().get("networkStats/browseTimeByHost?durationInDays=7").then(response => response.data)

export const hourlyDataUsageApi = () => getAuthenticatedAxiosInstance().get("networkStats/hourlyDataUsage?durationInDays=7").then(response => response.data)

export const networkUsageApi = () => getAuthenticatedAxiosInstance().get("networkStats/networkUsage?durationInDays=7").then(response => response.data)

export const fileIncidentsReportDownload = (durationInDays) => getAuthenticatedAxiosInstance().get(`reports/fileIncidents?durationInDays=${durationInDays}`, {
  responseType: "blob"
}).then(response => {
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.download = "filesDiscover.csv";
    a.click();
    a.remove();
  })

export const itAssetReportDownload = (durationInDays) => getAuthenticatedAxiosInstance().get(`reports/itAssetReport?durationInDays=${durationInDays}`, {
  responseType: "blob"
}).then(response => {
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.download = "itAssetReport.xlsx";
    a.click();
    a.remove();
  })

export const fileEventsReportDownload = (durationInDays) => getAuthenticatedAxiosInstance("blob").get(`reports/fileEvents?durationInDays=${durationInDays}`, {
  responseType: "blob"
}).then(response => {
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.download = "fileMonitor.csv";
    a.click();
    a.remove();
  })

export const networkReportDownload = (durationInDays) => getAuthenticatedAxiosInstance("blob").get(`reports/networkUsageReport?durationInDays=${durationInDays}`, {
  responseType: "blob"
}).then(response => {
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.download = "networkUsageReport.xlsx";
    a.click();
    a.remove();
  })

export const emailEventsReportDownload = (durationInDays) => getAuthenticatedAxiosInstance("blob").get(`reports/emailEvents?durationInDays=${durationInDays}`, {
  responseType: "blob"
}).then(response => {
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.download = "emailMonitor.csv";
    a.click();
    a.remove();
  })

export const gdriveIncidentReportDownload = (durationInDays) => getAuthenticatedAxiosInstance("blob").get(`reports/gDriveIncidents?durationInDays=${durationInDays}`, {
  responseType: "blob"
}).then(response => {
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.download = "driveDiscoverReport.csv";
    a.click();
    a.remove();
  })

export const gdriveEventReportDownload = (durationInDays) => getAuthenticatedAxiosInstance("blob").get(`reports/gDriveEvents?durationInDays=${durationInDays}`, {
  responseType: "blob"
}).then(response => {
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.download = "gdriveMonitor.csv";
    a.click();
    a.remove();
  })

export const searchFilesApi = (isGdrive, fileName, contentHash, machineName) => getAuthenticatedAxiosInstance().get(`${api}/api/v1/search/files?fileName=${fileName}&isGdrive=${isGdrive}&machineName=${machineName}&contentHash=${contentHash}`).then(response => response.data)

export const searchEmailsApi = (username) => getAuthenticatedAxiosInstance().get(`search/emails?username=${username}`).then(response => response.data)

export const osDistributionGetAPI = () => getAuthenticatedAxiosInstance().get("discover/osDistribution?durationInDays=7").then(response => response.data)

export const avDistributionGetAPI = () => getAuthenticatedAxiosInstance().get("discover/avDistribution?durationInDays=7").then(response => response.data)

export const getOsDetails = () => getAuthenticatedAxiosInstance().get("discover/osDetails?durationInDays=7").then(response => response.data)

export const getAvDetails = () => getAuthenticatedAxiosInstance().get("discover/avDetails?durationInDays=7").then(response => response.data)

export const getAgentRunningStatus = () => getAuthenticatedAxiosInstance().get("discover/agentRunningStatus?durationInDays=7").then(response => response.data)
