import React from "react";
import { Spinner } from "reactstrap";
import * as API from "../../../utils/api";
import StackedAreaChart from "../../Dashboard/Summary/Graphs/StackedAreaChart";
import "../../Dashboard.css";
import * as CardStyles from "../../../styles/CardStyles";
import Card from "react-bootstrap/Card";

export default class NetworkUsageByDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.hourlyDataUsageApi()
      .then((data) => {
        const entries = Object.entries(data.hourDateMap);
        const dlpStatsValue = entries.map(function (element, keyIndex) {
          const allDailyEntries = Object.entries(element[1]);
          return {
            hour: element[0],
            today: allDailyEntries[6][1],
            day6: allDailyEntries[5][1],
            day5: allDailyEntries[4][1],
            day4: allDailyEntries[3][1],
            day3: allDailyEntries[2][1],
            day2: allDailyEntries[1][1],
            day1: allDailyEntries[0][1],
          };
        });

        var newArray = dlpStatsValue.flat([1]);
        this.setState({ dlpStatsArray: newArray });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }
  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              8 AM to 8 PM Traffic Analysis (MB)
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              8 AM to 8 PM Traffic Analysis (MB)
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            8 AM to 8 PM Traffic Analysis (MB)
          </Card.Header>
          <Card.Body >
            <StackedAreaChart data={this.state.dlpStatsArray} />
          </Card.Body>
        </Card>
      </div>
    );
  }
}
