import React, { Component } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../Dashboard/Dashboard.css";

am4core.useTheme(am4themes_animated);

export default class StackedBarChart extends Component {
  constructor(props) {
    super(props);
    this.createSeries = this.createSeries.bind(this);
  }

  componentDidMount() {
    let chart = am4core.create("stackedBarchartDiv", am4charts.XYChart3D);
    chart.data = this.props.data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "xAxis";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 22;

    categoryAxis.renderer.fontSize = 12;
    //Value Axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    this.createSeries(chart, "yAxis2", "Total Files");
    this.createSeries(chart, "yAxis1", "Sensitive Files");
    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.fontSize = 11;
  }

  // Create series
  createSeries(chart, field, name) {
    // Set up series
    var series = chart.series.push(new am4charts.ColumnSeries3D());
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "xAxis";
    series.sequencedInterpolation = false;

    // Make it stacked
    series.stacked = true;

    // Configure columns
    //  series.columns.template.width = am4core.percent(60);
    series.columns.template.width = 45;
    series.columns.template.tooltipText =
      "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

    // Add label
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.locationY = 0.3;

    return series;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id="stackedBarchartDiv"></div>;
  }
}
