import React from "react";
import Card from "react-bootstrap/Card";
import { Spinner, CustomInput, Button } from "reactstrap";

export default function ToggleableBoxComponent(props) {
  return (
    <div className={props.colWidth ? props.colWidth : "col-sm-2"}>
      {getSwitch(props)}
      <Card
        style={
          props.activated
            ? styles.activatedPolicies
            : styles.deactivatedPolicies
        }
      >
        <Card.Body
          style={{
            paddingLeft: "8px",
            paddingRight: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Card.Text
            style={{ textAlign: "center", fontSize: "16px", color: "white" }}
          >
            {props.text}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

const getSwitch = (props) => {
  if (props.showToggleSwitch) {
    return (
      <CustomInput
        type="switch"
        id={props.id}
        onChange={(event) => {
          props.onToggle(event);
        }}
        checked={props.activated}
      />
    );
  } else {
    return <div />;
  }
};

const styles = {
  activatedPolicies: {
    margin: "2px",
    background: "#0f7acc",
    minHeight: "100px",
    borderColor: "#0f7acc",
    borderWidth: 0,
  },
  deactivatedPolicies: {
    margin: "2px",
    background: "#6f7b85",
    minHeight: "100px",
    borderColor: "#6f7b85",
    borderWidth: 0,
  },
};
