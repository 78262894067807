import React from "react";
import { Spinner } from "reactstrap";
import * as API from "../../../../utils/api";
import "../../Dashboard.css";
import * as CardStyles from "../../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import BarChart3D from "../../../generic/BarChart3D";

export default class NetworkUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.networkUsageApi()
      .then((data) => {
        const dlpStatsValue = data.map((element) => {
          return {
            xAxis: element.name,
            yAxis1: parseFloat(element.dataFlow / (1024 * 1024 * 1024)).toFixed(
              1
            ),
            color: "#6794dc",
          };
        });

        dlpStatsValue.height = 185;
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Network Usage (GB)
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }
    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Network Usage (GB)
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Network Usage (GB)
          </Card.Header>
          <Card.Body class="bm-card-body">
            <BarChart3D data={this.state.dlpStatsArray} />
          </Card.Body>
        </Card>
      </div>
    );
  }
}
