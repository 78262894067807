import React from "react";
import LoginPage from "./LoginPage";
import "./LoginPage.css";

export default class LoginLayout extends React.Component {
  render() {
    if (this.props.isToggleAttempted) {
      this.props.toggleAllowedCallback(true);
    }
    return (
      <div className="bm-website">
        <LoginPage />
      </div>
    );
  }
}
