import React from "react";
import { Row, Col } from "reactstrap";
import * as API from "../../utils/api";
import "../../components/Dashboard.css";
import * as CardStyles from "../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Utils from "../../utils/Utils";

export default class CloudConfigsComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      alreadyProvisioned: false,
      connectorTypeSelected: CONNECTOR_TYPES.GSuite,
    };
  }

  changeHandler(e) {
    console.log("connector: " + e.target.value);
    this.setState({ connectorTypeSelected: e.target.value });
  }

  connectGoogleCloud(e) {
    API.oauthRedirectUrlAPI().then((data) => {
      window.location.assign(data.url);
    });
  }

  connectO365() {
    let response = API.getO365AdminConsentUrl().then(data => {
      console.log("results: " + data);
      window.location.assign(data);
    })
  }

  componentDidMount() {
    API.provisioningGetAPI()
      .then((data) => {
        const dlpStatsValue = data.map((element) => {
          return {
            tenantName: element.name,
            serviceName: element.connectorType,
            tenantId: element.cloudEnterpriseId,
            installerUserName: element.installerUserName,
            installerEmailId: element.installerEmailId,
            createdAt: Utils.parseDate(element.createdAt),
            status: element.status === "OK" ? "Connected" : "Account misconfigured",
            action: element.status === "OK" ? "No action required" : "Please reconnect",
          };
        });

        this.setState({ dlpStatsArray: dlpStatsValue });
        if (dlpStatsValue.length > 0) this.setState({ alreadyProvisioned: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (this.props.isToggleAttempted) {
      this.props.acceptToggle(true);
    }
    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Service Configurations
          </Card.Header>
          <Card.Body>
            <div style={{ margin: "10px" }}>
              <p hidden={this.state.alreadyProvisioned} style={{ textAlign: "left", color: "red" }}>
                No service configured
              </p>
              <p hidden={!this.state.alreadyProvisioned} style={{ textAlign: "left" }}>
                Service Details:
              </p>
              <Table hidden={!this.state.alreadyProvisioned} striped
                style={{
                  background: "white",
                  fontSize: "12px",
                  textAlign: "left",
                }}
                size="sm"
              >
                <thead style={{ color: "white", background: "grey" }}>
                  <tr>
                    <th>Cloud Service</th>
                    <th>Tenant Name</th>
                    <th>Tenant ID</th>
                    <th>Provisioned At</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody style={{ background: "white" }}>
                  {this.state.dlpStatsArray.map((data, key) => {
                    return (
                      <tr key={data.tenantId}>
                        <td>{data.serviceName}</td>
                        <td>{data.tenantName}</td>
                        <td>{data.tenantId}</td>
                        <td>{data.createdAt}</td>
                        <td>{data.status}</td>
                        <td>{data.action}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <br />
              <p hidden={this.state.alreadyProvisioned} style={{ textAlign: "left" }}>
                Please follow below steps to configure the Bytemonk service with
                your Cloud account:
              </p>
              <br />
              <p hidden={this.state.alreadyProvisioned} style={{ textAlign: "left" }}>
                STEP 1: Select the service from the below drop-down list and press the Connect button.
              </p>
              <p hidden={this.state.alreadyProvisioned} style={{ textAlign: "left" }}>
                STEP 2: On the next consent screen that will come up, please read the details and click on Allow button (if required).
              </p>

              <Row hidden={this.state.alreadyProvisioned}>
                <Col sm="2">
                  <p style={{ textAlign: "left", color: "#1E90FF" }}>
                    Select the Service:
                  </p>
                </Col>
                <Col sm="2">
                  <Form.Control style={{ textAlign: "left", color: "#1E90FF", background: "white" }} as="select" 
                    onChange={e => this.changeHandler(e)} >
                    <option value={CONNECTOR_TYPES.GSuite}>GSuite</option>
                    <option value={CONNECTOR_TYPES.O365}>O365</option>
                  </Form.Control>
                </Col>
              </Row>
              <Button hidden={this.state.alreadyProvisioned} style={{ float: "left" }} variant="primary" type="submit"
                onClick={() => this.state.connectorTypeSelected === "GSuite" ? this.connectGoogleCloud() : this.connectO365()} >
                Connect
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const CONNECTOR_TYPES = {
  GSuite: "GSuite",
  O365: "O365",
}
