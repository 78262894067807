import React from "react";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserGroups from "../Users/UserGroups";
import Users from "../Users/Users";
import "../Monitor/monitor.css";
import "../../components/Dashboard.css";

export default class BackupMain extends React.Component {
  constructor(props) {
    super(props);
    if(sessionStorage.getItem("userTab")==null){
      sessionStorage.setItem("userTab","1")
      sessionStorage.setItem("userTabText", "Users: Groups")
    }
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: sessionStorage.getItem("userTab"),
      tabText: sessionStorage.getItem("userTabText"),
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    let tabTextVal;
    if (selectedTab === "1") {
      tabTextVal = "Users: Groups";
      sessionStorage.setItem("userTab","1")
      sessionStorage.setItem("userTabText", "Users: Groups")
    } else if (selectedTab === "2") {
      tabTextVal = "Users: Roles";
      sessionStorage.setItem("userTab","2")
      sessionStorage.setItem("userTabText", "Users: Roles")
    }

    this.setState({
      activeTab: selectedTab,
      tabText: tabTextVal,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="dashboard-header">
          {this.state.tabText}
          <hr style={{ marginTop: "-1px" }}></hr>
        </div>
        <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect}>
          <Tab eventKey={1} title="Groups" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              User groups management
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={this.state.activeTab}
              className="dashboard-tab-bg"
            >
              <TabPane tabId="1">
                <Row>
                  <Col sm="6">
                    <UserGroups />
                  </Col>
                  <Col sm="6" style={{ background: "white" }}></Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>
          <Tab eventKey={2} title="Roles" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              User roles management
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="2" className="dashboard-tab-bg">
                <Row>
                  <Col>
                    <Users />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
