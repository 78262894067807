import React from "react";
import { Col, Row, Spinner, Table, Button, Form, Badge, Card } from "react-bootstrap";
import { CustomInput, Input } from "reactstrap";
import * as CardStyles from "../../styles/CardStyles";
import activePencilImage from "../../images/active_pencil.png";
import inactivePencilImage from "../../images/inactive_pencil.png";
import tickImage from "../../images/tick.png";
import crossImage from "../../images/cross.png";
import Modal from "react-bootstrap/Modal";
import * as API from "../../utils/api";
import "./Configuration.css";
import _ from "lodash";

export default class AppControlConfigsComponent extends React.Component {
  BLACKLIST_MODE = "BLACKLIST";
  WHITELIST_MODE = "WHITELIST";

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      mode: this.BLACKLIST_MODE,
      appControlUrlData: [],
      appControlDesktopAppData: [],
      appControlMachineExclusions: [],
      editMode: false,
      blockUploadEditMode: false,
      showBlockFileUploadHelpModal: false,
      showSpinner: false,
      modalData: {
        mode: null,
        domainListFile: null,
        desktopAppsListFile: null,
        machineExclusionListFile: null
      },
      errorAlert: {
        show: false,
        text: ""
      }
    }
  }

  componentDidMount() {
    API.getAppControlConfig().then(appControlConfig => {
      this.setState({ 
        isLoading: false,
        mode: !appControlConfig.mode ? this.BLACKLIST_MODE : appControlConfig.mode,
        appControlUrlData: appControlConfig.urlList,
        appControlDesktopAppData: appControlConfig.desktopApplicationList,
        appControlMachineExclusions: appControlConfig.machineExclusionList,
        modalData: {
          mode: appControlConfig.mode
        }
      });
    });
    this.setState({ isLoading: true });
  }

  render() {
    if (this.state.isLoading == true) {
      return (
        <div>
          <Spinner type="grow" size="sm" color="primary" />{" "}
          <Spinner type="grow" size="sm" color="secondary" />
          Loading.....
        </div>)
    }
    if (this.props.isToggleAttempted) {
      this.props.acceptToggle(true);
    }
    return (
      <div>
        <Row>
          <Col sm="6">
            <Card className="bm-center-card">
              <Card.Header
                style={{...CardStyles.cardHeader, paddingLeft: "40px"}}
              >
                {(this.state.mode != null ? _.upperFirst(this.state.mode.toLowerCase()) : _.upperFirst(this.BLACKLIST_MODE.toLowerCase())) + "ed URL Access"}
              </Card.Header>
              <Card.Body style={styles.tableCardBody}>
                <Table
                  striped
                  responsive
                  style={{ ...styles.table, width: "100%" }}
                  size="sm"
                >
                  <colgroup>
                    <col span="1" style={{ width: "60%" }} />
                    <col span="1" style={{ width: "40%" }} />
                  </colgroup>

                  <thead style={styles.tableHeader}>
                    <tr>
                      <th style={{ fontWeight: "normal", textAlign: "left", verticalAlign: "middle", paddingLeft: "40px" }}>
                        Domain List
                      </th>
                      <th style={{ fontWeight: "normal", paddingLeft: "18px", verticalAlign: "middle" }}>
                        <Row style={{ display: "flex", justifyContent: "end" }}>
                          <Col sm="9" style={{ display: "flex", justifyContent: "end", alignItems: "center", paddingRight: "8px" }} >Only Block Upload</Col>
                          <Col sm="1" style={{ padding: "0px" }}>
                            <Button variant="link" style={{ padding: "0px" }} onClick={() => {
                                this.setState({ showBlockFileUploadHelpModal: true });
                              }}
                            >
                              <Badge as="button" pill variant="primary" style={{ fontSize: "10px", height: "16px" }}>?</Badge>
                            </Button>
                          </Col>
                          <Col sm="2" style={{ paddingLeft: "0px" }}>
                            {!this.state.blockUploadEditMode
                              ? (this.state.appControlUrlData.length > 0
                                ? <Button style={styles.blockUploadEditModeButton} onClick={() => {
                                  this.setState({ blockUploadEditMode: true });
                                }}>
                                  <img src={activePencilImage} style={{ height: "12px" }} />
                                </Button>
                                : <Button style={{...styles.blockUploadEditModeButton, active: false}}>
                                  <img src={inactivePencilImage} style={{ height: "12px" }} />
                                </Button>)
                              : (
                                <div style={{ display: "flex" }}>
                                  <Button style={styles.blockUploadEditModeButton} onClick = {() => {
                                    API.updateAppControlConfig({
                                      mode: this.state.mode,
                                      urlList: this.state.appControlUrlData,
                                      desktopApplicationList: this.state.appControlDesktopAppData,
                                      machineExclusionList: this.state.appControlMachineExclusions
                                    }).then(() => {
                                      window.location.reload();
                                    });
                                    this.setState({ isLoading: true });
                                  }}>
                                    <img src={tickImage} style={{ height: "12px" }} />
                                  </Button>
                                  <Button style={styles.blockUploadEditModeButton} onClick={() => {
                                    this.setState({ blockUploadEditMode: false });
                                    window.location.reload();
                                  }}>
                                    <img src={crossImage} style={{ height: "12px" }} />
                                  </Button>
                                </div>)
                            }
                          </Col>
                        </Row>
                      </th>
                    </tr>
                  </thead>
                  <tbody style={styles.tableBody}>
                    {this.state.appControlUrlData.map((data) => {
                      return (
                        <tr key={data.url} style={{ textAlign: "center" }}>
                          <td style={{ textAlign: "left", paddingLeft: "40px" }}> {data.url}</td>
                          <td style={{ display: "flex", justifyContent: "end", paddingRight: "120px" }}>
                            <CustomInput
                              type="switch"
                              id={data.url}
                              disabled={!this.state.blockUploadEditMode}
                              onChange={(event) => {
                                data.blockUpload = !data.blockUpload;
                                console.log(this.state.appControlUrlData)
                                this.setState({ appControlUrlData: this.state.appControlUrlData });
                              }}
                              checked={data.blockUpload}
                              style={{ alignItems: "center" }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="bm-center-card">
              <Card.Header
                style={{...CardStyles.cardHeader, paddingLeft: "40px"}}
              >
                {"Blocked Desktop App"}
              </Card.Header>
              <Card.Body style={styles.tableCardBody}>
                <Table
                  striped
                  responsive
                  style={{ ...styles.table, width: "100%" }}
                  size="sm"
                >
                  <thead style={styles.tableHeader}>
                    <tr>
                      <th style={{ fontWeight: "normal", textAlign: "left", verticalAlign: "middle", paddingLeft: "40px" }}>
                        Desktop Application List
                      </th>
                    </tr>
                  </thead>
                  <tbody style={styles.tableBody}>
                    {this.state.appControlDesktopAppData.map((data) => {
                      return (
                        <tr key={data.executable} style={{ textAlign: "center" }}>
                          <td style={{ textAlign: "left", paddingLeft: "40px" }}> {data.executable}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="bm-center-card">
              <Card.Header
                style={{...CardStyles.cardHeader, paddingLeft: "40px"}}
              >
                Machine Exclusion
              </Card.Header>
              <Card.Body style={styles.tableCardBody}>
                <Table
                  striped
                  responsive
                  style={styles.table}
                  size="sm"
                >
                  <thead style={styles.tableHeader}>
                    <tr>
                      <th style={{ fontWeight: "normal", paddingLeft: "40px" }}
                      >
                        Exclusion List
                      </th>
                    </tr>
                  </thead>
                  <tbody style={styles.tableBody}>
                    {this.state.appControlMachineExclusions.map((excludedMachine) => {
                      return (
                        <tr key={excludedMachine}>
                          <td style={{ paddingLeft: "40px" }}> {excludedMachine} </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div
          className="row"
          style={{ background: "white", marginBottom: "16px", marginLeft: "0px", marginRight: "0px" }}
        >
          <Button style={{ marginLeft: "12px", marginTop: "12px", marginBottom: "12px", width: "100px" }} variant="primary" onClick={() => {
              this.setState({ editMode: true })
          }}>
            Edit
          </Button>
        </div>
        <Modal show={this.state.editMode} backdrop="static" onHide={() => {
          this.setState({ editMode: false })
        }}>
          <Modal.Header closeButton={false}>
            <Modal.Title> App Controls Configuration </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Row} style={{ display: "flex", justifyContent: "center", marginTop: "4px", marginBottom: "4px", paddingLeft: "16px", paddingRight: "16px" }}>
                <Form.Label column sm="6" style={{paddingLeft: "0px"}}>
                  Access Control Mode
                </Form.Label>
                <Col sm="6" style={{ display: "flex", alignItems: "center", paddingLeft: "0px", paddingRight: "0px" }}>
                  <Form.Select style={{ color: "#0070C0", background: "white", borderRadius: "4px", height: "32px", width: "inherit" }} onChange={event => this.setState({ modalData: {...this.state.modalData, mode: event.target.value.toUpperCase() } })}>
                    <option selected={this.state.modalData.mode === this.BLACKLIST_MODE}>{_.upperFirst(this.BLACKLIST_MODE.toLowerCase())}</option>
                    <option selected={this.state.modalData.mode === this.WHITELIST_MODE}>{_.upperFirst(this.WHITELIST_MODE.toLowerCase())}</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="domainAccessList" style={{ display: "flex", justifyContent: "center", marginTop: "20px", paddingLeft: "16px", paddingRight: "16px" }}>
                <Form.Label column sm="6">
                  <Row>Web Domain Access List</Row>
                  <Row style={{ fontSize: "12px" }}><i>Download <a style={{color: "blue", textDecoration: "underline"}} onClick={() => API.downloadAppControlCsv("domainAccessList", true)}>sample</a></i></Row>
                </Form.Label>
                <Col sm="3" style={{ paddingLeft: "0px" }}>
                  <Form.Label for="domain_list_upload" style={{ marginBottom: "0px", marginTop: "0px" }}>
                    <div class="btn" style={{ width: "100px", background: "#a5a5a5", height: "32px", display: "flex", alignItems: "center", justifyContent: "center", color: "black" }}>Browse...</div>
                  </Form.Label>
                  <div style={{ fontSize: "12px", height: "16px" }}>
                    {this.state.modalData.domainListFile ? this.state.modalData.domainListFile.name : "No files selected"}
                  </div>
                </Col>
                <Input style={{ width: "0px" }} type="file" id="domain_list_upload" accept="text/csv" onChange={event => this.setState({ modalData: {...this.state.modalData,  domainListFile: event.target.files[0] } })}/>
                <Col sm="3">
                  <Button variant="primary" onClick={() => API.downloadAppControlCsv("domainAccessList", false)}> Download </Button>
                </Col>
              </Form.Group>

              <div style={{ height: "0.5px", background: "#c9cdd4", marginTop: "16px" }}></div>

              <Form.Group as={Row} controlId="desktopAppsList" style={{ display: "flex", justifyContent: "center", marginTop: "20px", paddingLeft: "16px", paddingRight: "16px" }}>
                <Form.Label column sm="6">
                  <Row>Desktop App Access List</Row>
                  <Row style={{ fontSize: "12px" }}><i>Download <a style={{color: "blue", textDecoration: "underline"}} onClick={() => API.downloadAppControlCsv("desktopAppsList", true)}>sample</a></i></Row>
                </Form.Label>
                <Col sm="3" style={{ paddingLeft: "0px" }}>
                  <Form.Label for="desktop_apps_list_upload" style={{ marginBottom: "0px", marginTop: "0px" }}>
                    <div class="btn" style={{ width: "100px", background: "#a5a5a5", height: "32px", display: "flex", alignItems: "center", justifyContent: "center", color: "black" }}>Browse...</div>
                  </Form.Label>
                  <div style={{ fontSize: "12px", height: "16px" }}>
                    {this.state.modalData.desktopAppsListFile ? this.state.modalData.desktopAppsListFile.name : "No files selected"}
                  </div>
                </Col>
                <Input style={{ width: "0px" }} type="file" id="desktop_apps_list_upload" accept="text/csv" onChange={event => this.setState({ modalData: {...this.state.modalData,  desktopAppsListFile: event.target.files[0] } })}/>
                <Col sm="3">
                  <Button variant="primary" onClick={() => API.downloadAppControlCsv("desktopAppsList", false)}> Download </Button>
                </Col>
              </Form.Group>

              <div style={{ height: "0.5px", background: "#c9cdd4", marginTop: "16px" }}></div>

              <Form.Group as={Row} controlId="machineExclusionList" style={{ display: "flex", marginTop: "20px", marginBottom: "4px", paddingLeft: "16px", paddingRight: "16px" }}>
                <Form.Label column sm="6">
                  <Row>Machine Exclusion List</Row>
                  <Row style={{ fontSize: "12px" }}><i>Download <a style={{color: "blue", textDecoration: "underline"}} onClick={() => API.downloadAppControlCsv("machineExclusionList", true)}>sample</a></i></Row>
                </Form.Label>
                <Col sm="3" style={{ paddingLeft: "0px" }}>
                  <Form.Label for="machine_exclusion_upload" style={{ marginBottom: "0px", marginTop: "0px"  }}>
                    <div class="btn" style={{ width: "100px", background: "#a5a5a5", height: "32px", display: "flex", alignItems: "center", justifyContent: "center", color: "black" }}>Browse...</div>
                  </Form.Label>
                  <div style={{ fontSize: "12px", height: "16px" }}>
                    {this.state.modalData.machineExclusionListFile ? this.state.modalData.machineExclusionListFile.name : "No files selected"}
                  </div>
                </Col>
                <Input style={{ width: "0px" }} type="file" id="machine_exclusion_upload" accept="text/csv" onChange={event => this.setState({ modalData: {...this.state.modalData,  machineExclusionListFile: event.target.files[0] } })}/>
                <Col sm="3">
                  <Button variant="primary" onClick={() => {
                    API.downloadAppControlCsv("machineExclusionList", false);
                    }}> Download </Button>
                </Col>
              </Form.Group>
            </Form>

            <Row style={{ marginTop: "16px" }}>
              <Col sm="2">
                <Button style={{ width: "100px" }} onClick={() => {
                  API.updateAppControlConfigBulk(this.state.modalData.mode, this.state.modalData.domainListFile, this.state.modalData.desktopAppsListFile, this.state.modalData.machineExclusionListFile
                    ).then(() => {
                      window.location.reload();
                    }).catch((e) => {
                      this.setState({ errorAlert: {show: true, text: e} })
                    });
                    this.setState({showSpinner: true});
                  }}>
                  Save
                </Button>
              </Col>
              <Col sm="2">
                <Button style={{ width: "100px", marginLeft: "32px" }} variant="secondary" onClick={() => 
                  this.setState({ editMode: false, modalData: {} })
                  }>
                  Cancel
                </Button>
              </Col>
            </Row>
            
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showBlockFileUploadHelpModal} onHide={() => {
          this.setState({ showBlockFileUploadHelpModal: false });
        }}>
          <Modal.Body>Turning on 'Only Block Upload' in Blacklist mode just restricts file upload while allowing the access to that domain.</Modal.Body>
        </Modal>
        <Modal size="sm" centered show={this.state.errorAlert.show} className={"error-modal"} onHide={() => this.setState({errorAlert: {show: false, text: ""}})}>
          <Modal.Header><Modal.Title> Operation failed </Modal.Title></Modal.Header>
          <Modal.Body>
            {this.state.errorAlert.text}
          </Modal.Body>
        </Modal>
        <Modal centered animation={false} show={this.state.showSpinner} className={"loader-modal"}>
          <Spinner animation="border" style={{ color: "#38acec", width: "64px", height: "64px" }} />
        </Modal>
      </div>
    );
  }
}

const styles = {
  blockUploadEditModeButton: {
    background: "transparent", outlineWidth: "0px", borderWidth: "0px", height: "16px", display: "flex", paddingLeft: "0px"
  },
  tableCardBody: {
    padding: "0px", overflow: "scroll", height: "320px"
  },
  table: { background: "white", fontSize: "16px", textAlign: "left", overflow: "hidden" },
  tableHeader: { color: "white", background: "grey" },
  tableBody: { background: "white" }
}