import React from "react";
import "./LoginPage.css";
import NavBar from "../../Dashboard/DashboardNavbar";
import { Form, FormGroup, Input, Col, Button, Row } from "reactstrap";
import * as API from "../../../utils/api";
import ls from "local-storage";

export default class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailSubmitted: false,
      email: "",
      statusText: "Generated password sent to registered email",
      resetSuccess: false,
      validate: {
        emailState: "",
      },
      resetTokenSubmissionForm: {
        email: "",
        resetToken: "",
        newPassword: "",
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
    if (emailRex.test(e.target.value)) {
      validate.emailState = "has-success";
    } else {
      validate.emailState = "has-danger";
    }
    this.setState({ validate });
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  handleLogin(e) {
    e.preventDefault();
    window.location.assign("/login");
  }

  submitForm(e) {
    e.preventDefault();
    API.ResetPasswordApi(this.state.email).then((data) => {
      if (data.success) {
        this.setState({
          isEmailSubmitted: true,
          submitResetTokenForm: {
            email: this.state.email,
          }
        });
      } else {
        console.log(data.errors);
      }
    });
  }

  submitResetTokenForm(e) {
    e.preventDefault();
    API.SubmitResetTokenApi(
      this.state.resetTokenSubmissionForm.email,
      this.state.resetTokenSubmissionForm.resetToken,
      this.state.resetTokenSubmissionForm.newPassword).then(data => {
        this.setState({ resetSuccess: true });
      });
  }

  render() {
    var isAuthenticated = ls.get("isAuthenticated");
    if (isAuthenticated) {
      window.location.assign("/dashboard");
    }
    return (
      <div>
        <NavBar />
        <div className="login-container">
          <Row>
            <Col sm="3" />
            <Col sm="3" />
            <Col sm="3" />
            <Col sm="3">
              <div style={{ color: "white" }}>
                {" "}
                Password Reset
                <hr style={{ borderColor: "white", marginTop: "-1px" }}></hr>
              </div>
              <div className="login-form" hidden={!this.state.isEmailSubmitted}>
                <Form className="form" onSubmit={(e) => this.submitResetTokenForm(e)}>
                  <FormGroup>
                    <Input type="email" name="resetTokenSubmissionForm.email" id="exampleEmail" placeholder="Email address" 
                      value={this.state.resetTokenSubmissionForm.email}
                      onChange={(e) => {
                        this.validateEmail(e);
                        this.setState({ resetTokenSubmissionForm: { ...this.state.resetTokenSubmissionForm, email: e.target.value } })
                      }}
                    />
                    <Input type="password" name="resetTokenSubmissionForm.resetToken" id="resetToken" placeholder="Reset token" 
                      value={this.state.resetTokenSubmissionForm.resetToken}
                      onChange={(e) => {
                        this.setState({ resetTokenSubmissionForm: { ...this.state.resetTokenSubmissionForm, resetToken: e.target.value } })
                      }}
                    />
                    <Input type="password" name="resetTokenSubmissionForm.newPassword" id="newPassword" placeholder="New password" 
                      value={this.state.resetTokenSubmissionForm.newPassword}
                      onChange={(e) => {
                        this.setState({ resetTokenSubmissionForm: { ...this.state.resetTokenSubmissionForm, newPassword: e.target.value } })
                      }}
                    />
                  </FormGroup>
                  <Button style={{ width: "100%" }} color="primary">
                    Submit
                  </Button>
                  <br />
                  <label
                    style={{
                      cursor: "pointer",
                      float: "right",
                      color: "white",
                    }}
                    onClick={this.handleLogin}
                    color="primary"
                  >
                    Login
                  </label>
                </Form>
              </div>
              <div className="login-form" hidden={this.state.isEmailSubmitted}>
                <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Email address"
                      value={this.state.email}
                      onChange={(e) => {
                        this.validateEmail(e);
                        this.handleChange(e);
                      }}
                    />
                  </FormGroup>
                  <Button style={{ width: "100%" }} color="primary">
                    Submit
                  </Button>
                  <br />
                  <label
                    style={{
                      cursor: "pointer",
                      float: "right",
                      color: "white",
                    }}
                    onClick={this.handleLogin}
                    color="primary"
                  >
                    Login
                  </label>
                </Form>
              </div>
              <div hidden={!this.state.isEmailSubmitted || this.state.resetSuccess} style={{color: "red"}}>
                Reset request submitted. Please check your email
              </div>
              <div hidden={!this.state.resetSuccess} style={{color: "red"}}>
                Password changed!!
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
