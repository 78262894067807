import React from "react";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SearchPage from "../Search/SearchPage";
import "../Monitor/monitor.css";
import "../../components/Dashboard.css";

export default class SearchMain extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="dashboard-header">
          File Query
          <hr style={{ marginTop: "-1px" }}></hr>
        </div>
        <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect}>
          <Tab eventKey={1} title="File Systems" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              Search: Files
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={this.state.activeTab}
              className="dashboard-tab-bg"
              style={{ marginBottom: "10px" }}
            >
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <SearchPage />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
