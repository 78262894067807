import React from "react";
import { Spinner } from "reactstrap";
import * as API from "../../../../utils/api";
import "../../Dashboard.css";
import * as CardStyles from "../../../../styles/CardStyles";
import Card from "react-bootstrap/Card";

export default class Top5RiskyUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskyUsersData: [],
      dlpStatsArray: [],
      Column1Name: "User Name",
      Column2Name: "Current Usage",
      Column3Name: "Usage Limit",
      Column4Name: "",
      Column5Name: "",
      ReportTitle: "Top 5 Email Users",
      OrgusageLimit: "",
      OrgcurrentUsage: "",
      username: "",
      UserusageLimit: "",
      UsercurrentUsage: "",
      graphArrayData: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.getRiskyUsers(5)
      .then((data) => {
        this.setState({
          riskyUsersData: data.entity,
          dataLoaded: true,
        });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }
  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader} className="bm-tab-heading">
              Top 5 Risky Users
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.state.riskyUsersData && this.state.riskyUsersData.length <= 0) {
      return (
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Top 5 Risky Users
          </Card.Header>
          <Card.Body class="bm-card-body">
            <h6>No Data Found</h6>
          </Card.Body>
        </Card>
      );
    }

    return (
      <div>
        <Card className="bm-center-card" >
          <Card.Header style={ CardStyles.cardHeader }>
            Top 5 Risky Users
          </Card.Header>
          <Card.Body class="bm-card-body">
            <div className="row" style={{ marginTop: "4px", justifyContent: "center" }}>
              {this.state.riskyUsersData.map(data => {
                return (<div className="col-sm-4" style={styles.card}>
                  <Card className="bm-top5email-data">
                    <Card.Body style={{ padding: "4px" }}>
                      <div style={{ fontSize: "15px" }}>
                        {data.identifierType == "email" ? "Email" : "Username"}
                        <br/><br/>
                        {data.userIdentifier}
                      </div>
                    </Card.Body>
                  </Card>
                </div>);
              })}
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const styles = {
  card: { marginTop: "4px", marginBottom: "4px", padding: "4px" },
  cardSubtitle: { fontSize: "1px" },
  gbUsed: { textAlign: "center", fontSize: "16px" },
  cardText: { marginBottom: "16px", textAlign: "center", fontSize: "16px" },
};
