import React, { Component } from "react";
import "./App.css";
import LoginLayout from "./components/Website/Login/LoginLayout";
import ForgotPasswordLayout from "./components/Website/Login/ForgotPasswordLayout";
import DashboardLayout from "./components/Dashboard/DashboardLayout";
import MonitorLayout from "./components/Monitor/MonitorLayout";
import DiscoverLayout from "./components/Discover/DiscoverLayout";
import BackupLayout from "./components/Backup/BackupLayout";
import ReportsLayout from "./components/Reports/ReportsLayout";
import UsersLayout from "./components/Users/UsersLayout";
import ConfigurationsLayout from "./components/Configurations/ConfigurationsLayout";
import SearchLayout from "./components/Search/SearchLayout";
import { BrowserRouter, Route, Switch } from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleAttempted: false,
    };
  }

  toggleCallback() {}

  acceptToggleCallback(isToggleAccepted) {
    this.toggleCallback(isToggleAccepted);
    this.setState({ isToggleAttempted: false });
    this.toggleCallback = () => {};
  }

  render() {
    return (
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          this.setState({ isToggleAttempted: true });
          this.toggleCallback = callback;
        }}
      >
        <Switch>
        <Route exact path="/" render={() => {
          return (
            <LoginLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route path="/login" render={() => {
          return (
            <LoginLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route path="/passwordReset" render={() => {
          return (
            <ForgotPasswordLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route path="/dashboard" render={() => {
          return (
            <DashboardLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route path="/monitor" render={() => {
          return (
            <MonitorLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route exact path="/discover" render={() => {
          return (
            <DiscoverLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route exact path="/discover/system" render={() => {
          return (
            <DiscoverLayout activeTab="4" isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route exact path="/discover/gdrive" render={() => {
          return (
            <DiscoverLayout activeTab="3" isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route path="/backup" render={() => {
          return (
            <BackupLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route path="/reports" render={() => {
          return (
            <ReportsLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route path="/users" render={() => {
          return (
            <UsersLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route exact path="/configurations" render={() => {
          return (
            <ConfigurationsLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route path="/query" render={() => {
          return (
            <SearchLayout isToggleAttempted={this.state.isToggleAttempted} toggleAllowedCallback={(isToggleAccepted) => {
                this.acceptToggleCallback(isToggleAccepted);
              }}
            />
          );}}
        />
        <Route render={() => {
          return (
            <div>
              404: Not found
            </div>
          )
        }}
        />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
