import React from "react";
import { Table, Spinner } from "reactstrap";
import * as API from "../../../../utils/api";
import "../../Dashboard.css";
import * as CardStyles from "../../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import PieChart3D from "../../../generic/PieChart3D";

export default class DisPolicyVoilationBySeverity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      Column1Name: "User Name",
      Column2Name: "Current Usage",
      Column3Name: "Usage Limit",
      Column4Name: "",
      Column5Name: "",
      ReportTitle: "Top 5 Email Users",
      OrgusageLimit: "",
      OrgcurrentUsage: "",
      username: "",
      UserusageLimit: "",
      UsercurrentUsage: "",
      graphArrayData: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.DashboardDiscoverPolicyVoilationBySeverity()
      .then((data) => {
        const dlpStatsValue = data.entity.map((element, index) => {
          return {
            severityCount: element.count,
            severityType: element.severity,
          };
        });
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Policy Violations by Severity
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Policy Violations by Severity
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Policy Violations by Severity
          </Card.Header>
          <Card.Body class="bm-card-body">
            <PieChart3D 
              data={this.state.dlpStatsArray}
              chartId="newChartDiv"
              value="severityCount"
              category="severityType"
              innerRadius="40"
            />

            <Table size="sm" style={{  width: "50%", marginLeft: "120px" }}>
              <tbody
                style={{
                  color: "#003a67",
                  fontSize: "11px",
                  background: "white",
                  textAlign: "left",
                }}
              >
                {this.state.dlpStatsArray.map((data, key) => {
                  return (
                    <tr key={key}>
                      <td>{data.severityType}</td>
                      <td>{data.severityCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
