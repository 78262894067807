import React from "react";
import { Spinner } from "reactstrap";
import "../../components/Dashboard.css";
import * as CardStyles from "../../styles/CardStyles";
import Table from "react-bootstrap/Table";
import * as API from "../../utils/api";
import * as Utils from "../../utils/Utils";
import Button from "react-bootstrap/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Form,
  FormGroup,
  Col,
  Row,
} from "reactstrap";
import md5 from "md5";
import Card from "react-bootstrap/Card";

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersArray: [],
      selectedUsername: "",
      selectedFirstname: "",
      selectedLastname: "",
      selectedEmail: "",
      selectedPassword: "",
      selectedMobile: "",
      chkEmailScanEnabled: "",
      selectedDomainName: "",
      selected: "",
      modal: false,
      unmountOnClose: "true",
      editForm: false,
      selectedRole: "2",
      dataLoaded: false,
      isLoading: false,
    };
    this.handleEditSelect = this.handleEditSelect.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeUnmountOnClose = this.changeUnmountOnClose.bind(this);
    this.toggle();
    this.handleChange = this.handleChange.bind(this);
    this.handleModalFormSubmit = this.handleModalFormSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  changeUnmountOnClose(e) {
    let value = e.target.value;
    this.setState({ unmountOnClose: JSON.parse(value) });
  }

  handleModalFormSubmit() {
    var email = this.state.selectedEmail;
    const {
      editForm,
      selectedEmail,
      selectedFirstname,
      selectedLastname,
      selectedPassword,
      chkEmailScanEnabled,
      selectedRole,
    } = this.state;
    if (editForm) {
      //Edit User api
      let groupId = 2;
      if (selectedRole !== "2") {
        groupId = 1;
      }

      API.updateUserApi(
        selectedEmail,
        md5(selectedPassword),
        chkEmailScanEnabled,
        groupId
      ).then((data) => {
        let usrArray = this.state.usersArray;
        for (var i = 0, l = usrArray.length; i < l; i++) {
          var obj = usrArray[i];
          if (obj.email === selectedEmail) {
            usrArray[i].scanEnabled = chkEmailScanEnabled ? "True" : "False";
            usrArray[i].roleName =
              selectedRole === "1" ? "System Administrators" : "Standard Users";
          }
        }
        this.setState({ usersArray: usrArray });
      });
    } else {
      API.userAddApi(
        selectedFirstname,
        selectedLastname,
        selectedEmail,
        md5(selectedPassword)
      ).then((data) => {
        
      });
    }
    this.toggle();
  }

  handleEditSelect(event) {
    this.setState({
      selectedUsername: event.userName,
      selectedFirstname: event.firstName,
      selectedLastname: event.lastName,
      selectedEmail: event.email,
      selectedMobile: event.mobile,
      chkEmailScanEnabled: event.chkEmailScanEnabled,
      selectedDomainName: event.domainName,
      selected: event.email,
      selectedRole: event.selectedRole,
      editForm: true,
    });
  }

  handleEditClick(event) {
    this.toggle();
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  };

  handleSelectChange(event) {
    this.setState({ selectedRole: event.target.value });
  }

  componentDidMount() {
    API.userGetAPI()
      .then((data) => {
        let usersValue = data.entity.map((element, index) => {
          return {
            userName: element.userName === "" ? "-" : element.userName,
            firstName: element.firstName,
            lastName: element.lastName,
            email: element.email,
            mobile: element.mobile === "" ? "" : element.mobile,
            scanEnabled: element.scanEnabled === true ? "True" : "False",
            chkEmailScanEnabled: element.scanEnabled === true ? true : false,
            domainName: element.domainName,
            selectedRole: element.groupId === 1 ? "1" : "2",
            roleName:
              element.groupId === 1
                ? "System Administrators"
                : "Standard Users",
            lastLogin:
              element.lastLogin == null
                ? "None"
                : Utils.parseDate(element.lastLogin),
          };
        });
        console.log(JSON.stringify(usersValue));
        this.setState({ usersArray: usersValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>User roles</Card.Header>
            <Card.Body  class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    // if (this.state.usersArray && this.state.usersArray.length <= 0) {
    //   return (
    //     <div>
    //       <Card className="bm-center-card">
    //         <Card.Header style={CardStyles.cardHeader}>User roles</Card.Header>
    //         <Card.Body>
    //           <Spinner type="grow" size="sm" color="primary" />{" "}
    //           <Spinner type="grow" size="sm" color="secondary" />
    //           Loading.....
    //         </Card.Body>
    //       </Card>
    //     </div>
    //   );
    // }

    const {
      editForm,
      selectedEmail,
      selectedFirstname,
      selectedLastname,
    } = this.state;
    let formText = editForm === true ? "Edit User" : "Create User";
    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>User Roles</Card.Header>
          <Card.Body class="bm-card-body">
            <Table
              striped
              responsive
              style={{ background: "white", fontSize: "12px" }}
              size="sm"
            >
              <thead
                style={{
                  color: "white",
                  background: "grey",
                  textAlign: "left",
                }}
              >
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Last Login</th>
                  <th>Scan Emails?</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody style={{ background: "white", textAlign: "left" }}>
                {this.state.usersArray.map((data, key) => {
                  return (
                    <tr
                      key={data.email}
                      onClick={() => this.handleEditSelect(data)}
                    >
                      <td>{data.firstName + " " + data.lastName}</td>
                      <td>{data.email}</td>
                      <td>{data.roleName}</td>
                      <td>{data.lastLogin}</td>
                      <td>{data.scanEnabled}</td>
                      <td>
                        <input
                          type="radio"
                          name="selectedUser"
                          checked={this.state.selected === data.email}
                          onChange={(e) => this.handleChange(e)}
                        />{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Button
              color="primary"
              style={{ float: "left" }}
              onClick={this.handleEditClick}
            >
              Edit
            </Button>
            <Button
              color="primary"
              hidden={true}
              onClick={this.handleCreateUser}
            >
              Create
            </Button>

            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={this.props.className}
              unmountonclose={this.state.unmountOnClose}
            >
              <ModalHeader toggle={this.toggle}>{formText}</ModalHeader>
              <ModalBody>
                <Form
                  className="form"
                  style={{ background: "white", fontSize: "12px" }}
                  onSubmit={(e) => this.submitForm(e)}
                >
                  <FormGroup>
                    <Row>
                      <Col sm="4">
                        <Label>Name</Label>
                      </Col>
                      <Col sm="8">
                        <Input
                          style={{ fontSize: "12px" }}
                          readOnly={true}
                          type="text"
                          name="selectedFirstname"
                          defaultValue={
                            selectedFirstname + " " + selectedLastname
                          }
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        <Label>Email Address</Label>
                      </Col>
                      <Col sm="8">
                        <Input
                          style={{ fontSize: "12px" }}
                          readOnly={true}
                          type="email"
                          name="selectedEmail"
                          id="txtEmail"
                          defaultValue={selectedEmail}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        <Label>Password</Label>
                      </Col>
                      <Col sm="8">
                        <Input
                          style={{ fontSize: "12px" }}
                          type="password"
                          name="selectedPassword"
                          defaultValue=""
                          id="txtPassword"
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        <Label>Role</Label>
                      </Col>
                      <Col sm="8">
                        <select
                          style={{ height: "30px", fontSize: "12px" }}
                          value={this.state.selectedRole}
                          onChange={this.handleSelectChange}
                        >
                          <option value="1">System Administrators</option>
                          <option value="2">Standard Users</option>
                        </select>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="4">
                        <Label>Scan Emails?</Label>
                      </Col>
                      <Col sm="8">
                        <Input
                          style={{
                            margin: "2px",
                            marginTop: "5px",
                            fontSize: "12px",
                          }}
                          type="checkbox"
                          name="chkEmailScanEnabled"
                          id="chkEmailScanEnabled"
                          checked={this.state.chkEmailScanEnabled}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Col>
                      <br />
                    </Row>
                  </FormGroup>
                  <Button
                    variant="primary"
                    style={{ margin: "5px", float: "left" }}
                    color="primary"
                    onClick={this.handleModalFormSubmit}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ margin: "5px", float: "left" }}
                    color="secondary"
                    onClick={this.toggle}
                  >
                    Cancel
                  </Button>
                </Form>
              </ModalBody>
            </Modal>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
