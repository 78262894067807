import React, { Component } from "react";
import Table from "react-bootstrap/Table";

export default class AvDistributionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avDistributionData: this.props.avDistributionData,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ avDistributionData: newProps.avDistributionData });
  }

  render() {
    return (
      <Table
        bordered={true}
        striped
        responsive
        style={{marginTop:"12px",  background: "white", fontSize: "12px", textAlign: "left" }}
        size="sm"
      >
        <colgroup>
          <col span="1" style={{ width: "20%" }} />
          <col span="1" style={{ width: "40%" }} />
          <col span="1" style={{ width: "20%" }} />
          <col span="1" style={{ width: "20%" }} />
        </colgroup>
        <thead style={{ color: "white", background: "grey" }}>
          <tr>
            <th style={{ whiteSpace: "nowrap" }}>Machine Name</th>
            <th style={{ whiteSpace: "nowrap" }}>Antivirus</th>
            <th style={{ whiteSpace: "nowrap" }}>Running?</th>
            <th style={{ whiteSpace: "nowrap" }}>Status</th>
          </tr>
        </thead>
        <tbody style={{ background: "white" }}>
          {this.state.avDistributionData.map((data, key) => {
            return (
              <tr key={key}>
                <td style={{ whiteSpace: "nowrap" }}> {data.machineName} </td>
                <td style={{ whiteSpace: "nowrap" }}>{data.name}</td>
                <td style={{ whiteSpace: "nowrap",textAlign:"center" }}>
                  {" "}
                  {this.getStatusView(data.protectionStatus === "ON")}
                </td>
                <td style={{ whiteSpace: "nowrap",textAlign:"center" }}>
                  {this.getStatusView(
                    data.signatureStatus === "UP_TO_DATE",
                    data.signatureStatus === "UP_TO_DATE"
                      ? "Updated"
                      : "Out of Date"
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  getStatusView(status, text) {
    if (status) {
      return (
        <span
          style={{
            backgroundColor: "#008000",
            color: "white",
            height: 16,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 4,
          }}
        >
          {text ? text : "Yes"}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "red",
            color: "white",
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 4,
          }}
        >
          {text ? text : "No"}
        </span>
      );
    }
  }
}
