import React from "react";
import DashboardNavBar from "./DashboardNavbar";
import SummaryMain from "./Summary/SummaryMain.js";
import ls from "local-storage";

export default class DashbaordLayout extends React.Component {
  render() {
    if (this.props.isToggleAttempted) {
      this.props.toggleAllowedCallback(true);
    }
    
    const isAuthenticated = ls.get("isAuthenticated");
    if (!isAuthenticated) {
      window.location.assign("/");
    }
    return (
      <div className="bm-website">
        <DashboardNavBar />
        <SummaryMain />
        <br />
        <br />
      </div>
    );
  }
}
