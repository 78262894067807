import React from "react";
import { TabContent, Row, Col } from "reactstrap";
import "../../../components/Dashboard.css";
import HealthSummary from "./HealthSummary";
import SummaryDiscover from "./SummaryDiscover";
import SummaryMonitor from "./SummaryMonitor";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export default class SummaryMain extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    return (
      <div>
        <div
          style={{
            marginBottom: "0px",
            color: "darkblue",
            fontSize: "16px",
            marginTop: "8px",
            marginBottom: "0px",
            textAlign: "left",
            paddingLeft: "5px",
          }}
        >
          Dashboard: Summary
          <hr style={{ marginTop: "-1px" }}></hr>
        </div>
        <div className="bm-dashboard-partition">
          <Tabs onSelect={this.handleSelect}>
            <Tab
              eventKey={1}
              title="Summary"
              tabClassName="bm-inactive-tab"
              style={{ fontSize: "30px" }}
            ></Tab>
          </Tabs>
        </div>
        <hr
          style={{
            marginTop: "-4px",
            size: "10",
            height: "2px",
            backgroundColor: "#004178",
          }}
        ></hr>
        <TabContent activeTab={this.state.activeTab}>
          <Row>
            <Col sm="12">
              <div className="bm-summary-bar">Activity for the last 7 days</div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <HealthSummary />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <SummaryMonitor />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <SummaryDiscover />
            </Col>
          </Row>
        </TabContent>
      </div>
    );
  }
}
