import React from "react";
import * as API from "../../../../utils/api";
import "../../Dashboard.css";
import * as CardStyles from "../../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import { Table, Spinner } from "reactstrap";
import PieChart3D from "../../../generic/PieChart3D";

export default class MonPolicyVoilationBySeverity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      Column1Name: "User Name",
      Column2Name: "Current Usage",
      Column3Name: "Usage Limit",
      Column4Name: "",
      Column5Name: "",
      ReportTitle: "Top 5 Email Users",
      OrgusageLimit: "",
      OrgcurrentUsage: "",
      username: "",
      UserusageLimit: "",
      UsercurrentUsage: "",
      graphArrayData: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.DashboardMonitorPolicyVoilationBySeverity()
      .then((data) => {
        const dlpStatsValue = data.entity.map((element) => {
          return {
            count: element.count,
            severity: element.severity,
          };
        });
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Data Risk Events by Severity
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <Card
            style={{
              minHeight: "360px",
              marginTop: "15px",
              marginBottom: "15px",
            }}
            className="bm-center-card"
          >
            <Card.Header style={CardStyles.cardHeader}>
              Data Risk Events by Severity
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card
          style={{
            minHeight: "360px",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          <Card.Header style={CardStyles.cardHeader}>
            Data Risk Events by Severity
          </Card.Header>
          <Card.Body class="bm-card-body">
            <PieChart3D
              data={this.state.dlpStatsArray}
              chartId="chartdiv"
              value="count"
              category="severity"
              innerRadius="40"
            />

            <Table size="sm" style={{ width: "50%", marginLeft: "120px" }}>
              <tbody
                style={{
                  color: "#003a67",
                  fontSize: "11px",
                  textAlign: "left",
                }}
              >
                {this.state.dlpStatsArray.map((data, key) => {
                  return (
                    <tr key={data.severity + data.count}>
                      <td>{data.severity}</td>
                      <td>{data.count}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
