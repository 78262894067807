import React from "react";
import { Table, Row, Col, Spinner } from "reactstrap";
import * as API from "../../../../utils/api";
import "../../Dashboard.css";
import * as CardStyles from "../../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import * as Utils from "../../../../utils/Utils";
import { Link } from "react-router-dom";

export default class HealthStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      filesBackupLastUpdatedDate: "None",
      subscriptionStatus: {},
      byteMonkServiceStatus: {},
      gSuiteStatus: {},
      windowsPatchesStatus: {},
      filesBackupStatus: {},
      antivirusStatus: {},
      subscriptionStatusMessages: [],
      byteMonkServiceStatusMessages: [],
      gSuiteStatusMessages: [],
      windowsPatchesStatusMessages: [],
      filesBackupStatusMessages: [],
      antivirusStatusMessages: [],
      graphArrayData: [],
      dataLoaded: false,
    };
    this.getColor = this.getColor.bind(this);
  }

  componentDidMount() {
    let fileStatus;
    API.DashboardOverviewHealthSummary()
      .then((data) => {
        this.setState({ dataLoaded: true });
        this.setState({ subscriptionStatus: data.entity.subscriptionStatus });
        this.setState({
          byteMonkServiceStatus: data.entity.byteMonkServiceStatus,
        });
        this.setState({ gSuiteStatus: data.entity.gSuiteStatus });
        this.setState({
          windowsPatchesStatus: data.entity.windowsPatchesStatus,
        });
        this.setState({ filesBackupStatus: data.entity.filesBackupStatus });
        fileStatus = data.entity.filesBackupStatus.status;
        this.setState({ antivirusStatus: data.entity.antivirusStatus });
        this.setState({
          subscriptionStatusMessages: data.entity.subscriptionStatus.messages,
        });
        this.setState({
          byteMonkServiceStatusMessages:
            data.entity.byteMonkServiceStatus.messages,
        });
        this.setState({
          gSuiteStatusMessages: data.entity.gSuiteStatus.messages,
        });
        this.setState({
          windowsPatchesStatusMessages:
            data.entity.windowsPatchesStatus.messages,
        });
        this.setState({
          filesBackupStatusMessages: data.entity.filesBackupStatus.messages,
        });
        this.setState({
          antivirusStatusMessages: data.entity.antivirusStatus.messages,
        });

        API.dataBackupSummaryApi()
          .then((data) => {
            const lastBackupDate = data.entity[0].lastBackupDate;
            if (lastBackupDate !== null && fileStatus !== "ALERT") {
              console.log("Setting lastBackupDate " + lastBackupDate);
              this.setState({
                filesBackupLastUpdatedDate: Utils.parseDate(lastBackupDate),
              });
            }
          })
          .catch((e) => {
            console.log("Error in Component Mount -> api fetch " + e);
          });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  getColor(status) {
    if (status === "Ok") {
      return "#3CB371";
    } else if (status === "WARNING") {
      return "#ffbf00";
    } else if (status === "ALERT") {
      return "#ff0000";
    }
    return "#ff0000";
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Row>
          <Col>
            <Card style={styles.cardLayout}>
              <Card.Header style={styles.innerCardHeader}>
                Bytemonk Service
              </Card.Header>
              <Link to="/discover/system#service">
              <Card.Body style={styles.innerCardLayout}>
                <Card.Subtitle>
                  <svg height="130px" width="100%">
                    <circle
                      cx={"50%"}
                      cy={"50%"}
                      r={40}
                      fill={this.getColor(
                        this.state.byteMonkServiceStatus.status
                      )}
                    />
                  </svg>
                </Card.Subtitle>
                  <Table size="sm" style={{ marginLeft: "0px" }}>
                    <tbody style={styles.cardTableMessage}>
                      <tr>
                        <td>Status</td>
                        <td>
                          {this.state.byteMonkServiceStatusMessages.status}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Link>
            </Card>
          </Col>

          <Col>
            <Card style={styles.cardLayout}>
              <Card.Header style={styles.innerCardHeader}>
                Antivirus
              </Card.Header>
              <Link to="/discover/system#av">
              <Card.Body style={styles.innerCardLayout}>
                <Card.Subtitle>
                  <svg height="130px" width="100%">
                    <circle
                      cx={"50%"}
                      cy={"50%"}
                      r={40}
                      fill={this.getColor(this.state.antivirusStatus.status)}
                    />
                  </svg>
                </Card.Subtitle>
                  <Table size="sm" style={{ marginLeft: "0px" }}>
                    <tbody style={styles.cardTableMessage}>
                      <tr>
                        <td>Status</td>
                        <td>{this.state.antivirusStatusMessages.status}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Link>
            </Card>
          </Col>

          <Col>
            <Card style={styles.cardLayout}>
              <Card.Header style={styles.innerCardHeader}>
                OS Updates
              </Card.Header>
              <Link to="/discover/system#os">
              <Card.Body style={styles.innerCardLayout}>
                <Card.Subtitle>
                  <svg height="130px" width="100%">
                    <circle
                      cx={"50%"}
                      cy={"50%"}
                      r={40}
                      fill={this.getColor(
                        this.state.windowsPatchesStatus.status
                      )}
                    />
                  </svg>
                </Card.Subtitle>
                  <Table size="sm">
                    <tbody style={styles.cardTableMessage}>
                      <tr>
                        <td>Status</td>
                        <td>
                          {this.state.windowsPatchesStatusMessages.status}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Link>
            </Card>
          </Col>

          <Col>
            <Card style={styles.cardLayout}>
              <Card.Header style={styles.innerCardHeader}>
                Files Backup
              </Card.Header>
              <Link to="/backup">
              <Card.Body style={styles.innerCardLayout}>
                <Card.Subtitle>
                  <svg height="130px" width="100%">
                    <circle
                      cx={"50%"}
                      cy={"50%"}
                      r={40}
                      fill={this.getColor(this.state.filesBackupStatus.status)}
                    />
                  </svg>
                </Card.Subtitle>
                  <Table size="sm" responsive style={{ marginLeft: "0px" }}>
                    <tbody style={styles.cardTableMessage}>
                      <tr>
                        <td>Status</td>
                        <td>{this.state.filesBackupStatusMessages.status}</td>
                      </tr>
                      <tr>
                        <td>Last Backup</td>
                        <td>{this.state.filesBackupLastUpdatedDate}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Link>
            </Card>
          </Col>

          <Col>
            <Card style={styles.cardLayout}>
              <Card.Header style={styles.innerCardHeader}>Cloud App</Card.Header>
              <Link to="/discover/gdrive">
              <Card.Body style={styles.innerCardLayout}>
                <Card.Subtitle style={{marginTop:'0px'}}>
                  <svg
                    height="130px"
                    width="100%"
                    style={{ marginLeft: "0px" }}
                  >
                    <circle
                      cx={"50%"}
                      cy={"50%"}
                      r={40}
                      fill={this.getColor(this.state.gSuiteStatus.status)}
                    />
                  </svg>
                </Card.Subtitle>
                
                  <Table size="sm" responsive>
                    <tbody style={styles.cardTableMessage}>
                      <tr>
                        <td>Status</td>
                        <td>{this.state.gSuiteStatusMessages.status}</td>
                      </tr>
                      <tr>
                        <td>Configured On</td>
                        <td>
                          {!this.state.gSuiteStatusMessages.Last_Updated
                            ? ""
                            : Utils.parseDate(
                                this.state.gSuiteStatusMessages.Last_Updated
                              )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const styles = {
  cardTableMessage: {
    color: "#003a67",
    background: "white",
    textAlign: "left",
  },
  cardLayout: {
    marginTop: "10px",
    height: "340px",
    marginBottom: "10px",
  },
    innerCardLayout: {
    marginTop: "5px",
    fontSize: "15px",
    height: "300px"
  },
  innerCardHeader : {
    background: "#44546a",
    color: "#FFFFFF",
    fontSize: "16px",
    textAlign: "left",
    borderColor: "white",
    height: "40px",
    marginTop:"18px"
  }
};
