import React, { Component } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../Dashboard/Dashboard.css";

am4core.useTheme(am4themes_animated);

export default class BarChart3DVertical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
    };
  }

  componentWillReceiveProps(newProps) {
    this.chart.data = newProps.data;
  }

  componentDidMount() {
    var chart = am4core.create(this.props.chartId, am4charts.XYChart3D);

    // Add data
    chart.data = this.props.data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "xAxis";
    categoryAxis.renderer.labels.template.hideOversized = false;
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.fontSize = 11;
    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.dx = -5;
    label.maxWidth = 80;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "yAxis1";
    series.dataFields.categoryX = "xAxis";
    series.name = "Visits";
    series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;
    series.columns.template.width = 45;
    series.columns.template.propertyFields.fill = am4core.color;

    let valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = "{valueY}";
    valueLabel.label.fontSize = 11;
    valueLabel.label.horizontalCenter = "center";
    valueLabel.label.dx = 0;

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = am4core.color("#FFFFFF");

    columnTemplate.adapter.add("fill", (fill, target) => {
      return chart.colors.getIndex(target.dataItem.index);
    });

    columnTemplate.adapter.add("stroke", (stroke, target) => {
      return chart.colors.getIndex(target.dataItem.index);
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineY.strokeOpacity = 0;
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id={this.props.chartId}></div>;
  }
}
