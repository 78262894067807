import React from "react";
import { Spinner } from "reactstrap";
import * as API from "../../../../utils/api";
import "../../Dashboard.css";
import * as CardStyles from "../../../../styles/CardStyles";
import StackedBarChart from "../../../generic/StackedBarChart";
import Card from "react-bootstrap/Card";

export default class DisPolicyVoilationByFileType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      Column1Name: "User Name",
      Column2Name: "Current Usage",
      Column3Name: "Usage Limit",
      Column4Name: "",
      Column5Name: "",
      ReportTitle: "Top 5 Email Users",
      OrgusageLimit: "",
      OrgcurrentUsage: "",
      username: "",
      UserusageLimit: "",
      UsercurrentUsage: "",
      graphArrayData: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.DashboardDiscoverPolicyVoilationByFileType()
      .then((data) => {
        const dlpStatsValue = data.entity.map((element, index) => {
          return {
            yAxis1: element.sensitiveFileCount,
            yAxis2: element.totalFileCount,
            xAxis: element.mimeType,
            yAxis1Label: "Sensitive File Count",
            yAxis2Label: "Total File Count",
          };
        });
        dlpStatsValue.sort((a, b) =>
          a.yAxis1 + a.yAxis2 > b.yAxis1 + b.yAxis2 ? 1 : -1
        );
        dlpStatsValue.reverse().slice(0, 3);
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Policy Violations by File Type
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Policy Violations by File Type
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Policy Violations by File Type
          </Card.Header>
          <Card.Body class="bm-card-body">
            <StackedBarChart data={this.state.dlpStatsArray} />
          </Card.Body>
        </Card>
      </div>
    );
  }
}
