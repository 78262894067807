import React from "react";
import "../../components/Dashboard.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import CloudConfigsComp from "../Configurations/CloudConfigsComp";
import AppConfigsComp from "../Configurations/AppControlConfigsComponent";
import GeneralConfigsComponent from "../Configurations/GeneralConfigsComponent";
import PolicyConfigsComponent from "../Configurations/PolicyConfigsComponent";
import LicenseManagementConfigs from "../Configurations/LicenseManagementConfigs";

export default class ConfigurationsMain extends React.Component {
  constructor(props) {
    super(props);
    if(sessionStorage.getItem("configTab")==null){
      sessionStorage.setItem("configTab","1")
      sessionStorage.setItem("configTabText", "Configurations: Policy")
    }
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: sessionStorage.getItem("configTab"),
      tabText: sessionStorage.getItem("configTabText"),
      selectedTab: "1",
      isToggleAttempted: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  acceptToggle(isToggleAccepted) {
    if (isToggleAccepted) {
      this.handleSelect(this.state.selectedTab);
    } else {
      this.setState({ selectedTab: this.state.activeTab });
    }
    this.setState({ isToggleAttempted: false });
    this.props.toggleAllowedCallback(isToggleAccepted);
  }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    let tabTextVal;
    if (selectedTab === "1") {
      tabTextVal = "Configurations: Policy";
      sessionStorage.setItem("configTab","1")
      sessionStorage.setItem("configTabText", "Configurations: Policy")
    }
    if (selectedTab === "2") {
      tabTextVal = "Configurations: Agent";
      sessionStorage.setItem("configTab","2")
      sessionStorage.setItem("configTabText", "Configurations: Agent")
    }
    if (selectedTab === "3") {
      tabTextVal = "Configurations: Cloud App";
      sessionStorage.setItem("configTab","3")
      sessionStorage.setItem("configTabText", "Configurations: Cloud App")
    }
    if (selectedTab === "4") {
      tabTextVal = "Configurations: App Controls";
      sessionStorage.setItem("configTab","4")
      sessionStorage.setItem("configTabText", "Configurations: App Controls")
    }

    this.setState({
      activeTab: selectedTab,
      tabText: tabTextVal,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    return (
      <div>
        <div className="dashboard-header">
          {this.state.tabText}
          <hr style={{ marginTop: "-1px" }}></hr>
        </div>
        <Tabs
          activeKey={this.state.activeTab}
          onSelect={(selectedTab) => {
            this.setState({
              selectedTab: selectedTab,
              isToggleAttempted: true,
            });
          }}
        >
          <Tab eventKey={1} title="Product" tabClassName="bm-inactive-tab">
            <hr style={styles.tabHeader}></hr>
            <div style={styles.tabContent} className="bm-summary-bar">
              License and Policy configuration details
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <LicenseManagementConfigs />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <PolicyConfigsComponent
                      isToggleAttempted={
                        (this.state.isToggleAttempted ||
                          this.props.isToggleAttempted) &&
                        this.state.activeTab === "1"
                      }
                      acceptToggle={(isToggleAccepted) => {
                        this.acceptToggle(isToggleAccepted);
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>

          <Tab eventKey={2} title="Agent" tabClassName="bm-inactive-tab">
            <hr style={styles.tabHeader}></hr>
            <div style={styles.tabContent} className="bm-summary-bar">
              Agent configuration details
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="2" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <GeneralConfigsComponent
                      isToggleAttempted={
                        (this.state.isToggleAttempted ||
                          this.props.isToggleAttempted) &&
                        this.state.activeTab === "2"
                      }
                      acceptToggle={(isToggleAccepted) => {
                        this.acceptToggle(isToggleAccepted);
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>

          <Tab eventKey={3} title="Cloud App" tabClassName="bm-inactive-tab">
            <hr style={styles.tabHeader}></hr>
            <div style={styles.tabContent} className="bm-summary-bar">
              Service configuration details
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="3" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <CloudConfigsComp
                      isToggleAttempted={
                        (this.state.isToggleAttempted ||
                          this.props.isToggleAttempted) &&
                        this.state.activeTab === "3"
                      }
                      acceptToggle={(isToggleAccepted) => {
                        this.acceptToggle(isToggleAccepted);
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>

          <Tab eventKey={4} title="App Controls" tabClassName="bm-inactive-tab">
            <hr style={styles.tabHeader}></hr>
            <div style={styles.tabContent} className="bm-summary-bar">
              App Controls configuration details
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("4");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="4" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <AppConfigsComp
                      isToggleAttempted={
                        (this.state.isToggleAttempted ||
                          this.props.isToggleAttempted) &&
                        this.state.activeTab === "4"
                      }
                      acceptToggle={(isToggleAccepted) => {
                        this.acceptToggle(isToggleAccepted);
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const styles = {
  tabHeader: {
    marginTop: "1px",
    size: "10",
    height: "2px",
    backgroundColor: "#004178",
  },
  tabContent: { marginTop: "10px", marginBottom: "10px" },
};
