import React from "react";
import * as API from "../../utils/api";
import "../../components/Dashboard.css";
import * as CardStyles from "../../styles/CardStyles";
import { Card, Button } from "react-bootstrap";
import { Modal, Spinner } from "react-bootstrap";
import { Input, Form, Label, Row, Col } from "reactstrap";

export default class DiscoverReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileDownload: true,
      gDriveDownload: false,
      itAssetDownload: false,
      durationDays: 7,
      isLoading: false,
    };
    this.handleReportDownload = this.handleReportDownload.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleReportDownload(e) {
    e.preventDefault();
    if (this.state.gDriveDownload) {
      API.gdriveIncidentReportDownload(this.state.durationDays).then(() => { this.setState({ isLoading: false }) });
    }
    if (this.state.fileDownload) {
      API.fileIncidentsReportDownload(this.state.durationDays).then(() => { this.setState({ isLoading: false }) });
    }
    if(this.state.itAssetDownload){
      API.itAssetReportDownload(this.state.durationDays).then(() => { this.setState({ isLoading: false }) });
    }
    this.setState({ isLoading: true });
  }

  handleChange = async (event) => {
    const { target } = event;
    if (target.id === "daysSelected7") {
      this.setState({ durationDays: 7 });
    }
    if (target.id === "daysSelected15") {
      this.setState({ durationDays: 15 });
    }
    if (target.id === "daysSelected30") {
      this.setState({ durationDays: 30 });
    }
    if (target.id === "fileSelected") {
      this.setState({ fileDownload: true });
      this.setState({ gDriveDownload: false });
      this.setState({ itAssetDownload: false });
    }
    if (target.id === "gDriveSelected") {
      this.setState({ gDriveDownload: true });
      this.setState({ fileDownload: false });
      this.setState({ itAssetDownload: false });
    }
    if (target.id === "itAssetReportSelected") {
      this.setState({ gDriveDownload: false });
      this.setState({ fileDownload: false });
      this.setState({ itAssetDownload: true });
    }
  };

  render() {
    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Discover Reports
          </Card.Header>
          <Card.Body class="bm-card-body">
            <Form className="form" style={{ fontSize: "14px", textAlign: "left" }} onSubmit={(e) => this.handleReportDownload(e)}
            >
              <Row>
                <Col sm="2">
                  <Label>Download Report:</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label>
                    <Input type="radio" name="entitySelected" id="fileSelected" defaultChecked="true"
                      onChange={(e) => this.handleChange(e)} />
                    {" "}
                    Files
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label>
                    <Input type="radio" name="entitySelected" id="gDriveSelected"
                      onChange={(e) => this.handleChange(e)} />
                    {" "}
                    Drive
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label>
                    <Input type="radio" name="entitySelected" id="itAssetReportSelected"
                      onChange={(e) => this.handleChange(e)} />
                    {" "}
                    System
                  </Label>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm="2">
                  <Label>Duration:</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label>
                    <Input type="radio" name="daysSelected" id="daysSelected7" defaultChecked="true"
                      onChange={(e) => this.handleChange(e)}
                    />{" "}
                    Last 7 days
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label>
                    <Input type="radio" name="daysSelected" id="daysSelected15"
                      onChange={(e) => this.handleChange(e)}
                    />{" "}
                    Last 15 days
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ marginLeft: "20px" }}>
                  <Label>
                    <Input type="radio" name="daysSelected" id="daysSelected30"
                      onChange={(e) => this.handleChange(e)}
                    />{" "}
                    Last 30 days
                  </Label>
                </Col>
              </Row>
              <br />
              <Button style={{ float: "left", marginBottom: "16px" }} variant="primary" type="submit"
                onClick={this.handleReportDownload}
              >
                Download{" "}
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <Modal centered animation={false} show={this.state.isLoading} className={"loader-modal"}>
          <Spinner animation="border" style={{ color: "#38acec", width: "64px", height: "64px" }} />
        </Modal>
      </div>
    );
  }
}
