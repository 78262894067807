export const cardHeader = {
  background: "#44546a",
  color: "#FFFFFF",
  fontSize: "16px",
  textAlign: "left",
  borderColor: "white",
  height: "40px",
  marginTop:"3px"
};

export const modeColor = {
  color: "#2196f3",
}

export const modeStyle = {
  fontWeight: 'bold',
}
