import React from "react";
import "./LoginPage.css";
import NavBar from "../../Dashboard/DashboardNavbar";
import { Form, FormGroup, Input, Col, Button, Row } from "reactstrap";
import * as API from "../../../utils/api";
import ls from "local-storage";
import md5 from "md5";

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginSuccess: true,
      errorText: "",
      validate: {
        emailState: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePwdReset = this.handlePwdReset.bind(this);
  }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
    if (emailRex.test(e.target.value)) {
      validate.emailState = "has-success";
    } else {
      validate.emailState = "has-danger";
    }
    this.setState({ validate });
  }

  handlePwdReset(e) {
    e.preventDefault();
    console.log("password reset clicked");
    window.location.assign("/passwordReset");
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  };

  submitForm(e) {
    e.preventDefault();
    var email = this.state.email;
    var password = md5(this.state.password);
    
    // API.UserAuthenticateApi(email, password).then((authResponse) => {
      // console.log(authResponse);
      // ls.set("jwt", authResponse.token);
    API.UserLoginApi(email, password).then((data) => {
      API.UserAuthenticateApi(email, password).then((authResponse) => {
        console.log(authResponse);  
        if (data.entity) {
          if (!data.entity.orgIdGuid || !authResponse.token) {
            console.log("Login failed");
            this.setState({ loginSuccess: false });
            this.setState({ errorText: "Invalid data format" });
            ls.set("isAuthenticated", false);
            return
          }
          console.log("Login successful with orgIdGuid " + data.entity.orgIdGuid);
          ls.set("isAuthenticated", true);
          ls.set("orgIdGuid", data.entity.orgIdGuid);
          ls.set("name", data.entity.firstName + " " + data.entity.lastName);
          ls.set("jwt", authResponse.token);
          window.location.assign("/dashboard");
        } else {
          this.setState({ loginSuccess: false });
          this.setState({ errorText: data.errors[0].message });
          ls.set("isAuthenticated", false);
        }
      })
    }).catch(e => {
      console.log(e);
    });
  }

  render() {
    var isAuthenticated = ls.get("isAuthenticated");
    if (isAuthenticated) {
      window.location.assign("/dashboard");
    }
    const { email, password } = this.state;

    return (
      <div>
        <NavBar showNavBar={false} />
        <div className="login-container">
          <Row>
            <Col sm="3" />
            <Col sm="3" />
            <Col sm="3" />
            <Col sm="3">
              <p hidden={this.state.loginSuccess} style={{ color: "red" }}>
                {this.state.errorText}
              </p>
              <div style={{ color: "white" }}>
                {" "}
                Login
                <hr style={{ borderColor: "white", marginTop: "-1px" }}></hr>
              </div>
              <div className="login-form">
                <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => {
                        this.validateEmail(e);
                        this.handleChange(e);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="password"
                      name="password"
                      id="examplePassword"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </FormGroup>
                  <Button style={{ width: "100%" }} color="primary">
                    Submit
                  </Button>
                  <br />
                  <label
                    style={{
                      cursor: "pointer",
                      float: "right",
                      color: "white",
                    }}
                    onClick={this.handlePwdReset}
                    color="primary"
                  >
                    Forgot your password?
                  </label>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
