import React, { Component } from "react";
import * as API from "../../../../utils/api";
import Table from "react-bootstrap/Table";

export default class VulnerableMachinesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      hidden: false,
    };
  }

  componentDidMount() {
    API.VulnerabilityDetails()
      .then((data) => {
        this.setState({ dlpStatsArray: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Table 
        bordered={true}
        hidden={this.state.hidden}
        striped
        responsive
        style={{marginTop:"12px",  background: "white", fontSize: "12px", textAlign: "left" }}
        size="sm"
      >
        <colgroup>
          <col span="1" style={{ width: "20%" }} />
          <col span="1" style={{ width: "40%" }} />
          <col span="1" style={{ width: "20%" }} />
          <col span="1" style={{ width: "20%" }} />
        </colgroup>
        <thead style={{ color: "white", background: "grey" }}>
          <tr>
            <th style={{ whiteSpace: "nowrap" }}>Machine Name</th>
            <th style={{ whiteSpace: "nowrap" }}>Bytemonk Service Running?</th>
            <th style={{ whiteSpace: "nowrap" }}>AV Updated?</th>
            <th style={{ whiteSpace: "nowrap" }}>OS Updated?</th>
          </tr>
        </thead>
        <tbody style={{ background: "white" }}>
          {this.state.dlpStatsArray.map((data, key) => {
            return (
              <tr key={key}>
                <td style={{ whiteSpace: "nowrap" }}> {data.uninstalled ? (<strike>{data.machineName}</strike>) : data.machineName} </td>
                <td style={{ whiteSpace: "nowrap",textAlign:"center" }}>
                  {this.getStatusView(data.bmServiceRunning)}
                </td>
                <td style={{ whiteSpace: "nowrap",textAlign:"center" }}>
                  {this.getStatusView(data.signatureStatus !== "OUT_OF_DATE")}
                </td>
                <td style={{ whiteSpace: "nowrap",textAlign:"center" }}>
                  {" "}
                  {this.getStatusView(data.osUpdated)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  getStatusView(status, text) {
    if (status) {
      return (
        <span
          style={{
            backgroundColor: "#008000",
            color: "white",
            height: 16,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 4,
          }}
        >
          {text ? text : "Yes"}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "red",
            color: "white",
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 4,
          }}
        >
          {text ? text : "No"}
        </span>
      );
    }
  }
}
