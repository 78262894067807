import React from "react";
import NavBar from "../Dashboard/DashboardNavbar";
import DiscoverMain from "../Discover/DiscoverMain";
import ls from "local-storage";

export default class DiscoverLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
    };
  }

  render() {
    if (this.props.isToggleAttempted) {
      this.props.toggleAllowedCallback(true);
    }
    const isAuthenticated = ls.get("isAuthenticated");
    if (!isAuthenticated) {
      window.location.assign("/");
    }
    return (
      <div className="bm-website">
        <NavBar />
        <DiscoverMain activeTab={this.state.activeTab} />
      </div>
    );
  }
}
