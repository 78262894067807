import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import { Button } from "reactstrap";

export default class OsDistributionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      osDistributionData: this.props.osDistributionData,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ osDistributionData: newProps.osDistributionData });
  }

  render() {
    return (
      <Table
        bordered={true}
        striped
        responsive
        style={{ marginTop:"12px", background: "white", fontSize: "12px", textAlign: "left" }}
        size="sm"
      >
        <colgroup>
          <col span="1" style={{ width: "20%" }} />
          <col span="1" style={{ width: "40%" }} />
          <col span="1" style={{ width: "20%" }} />
          <col span="1" style={{ width: "20%" }} />
        </colgroup>
        <thead style={{ color: "white", background: "grey" }}>
          <tr>
            <th style={{ whiteSpace: "nowrap" }}>Machine Name</th>
            <th style={{ whiteSpace: "nowrap" }}>OS</th>
            <th style={{ whiteSpace: "nowrap" }}>Updated?</th>
            <th style={{ whiteSpace: "nowrap" }}>Activated?</th>
          </tr>
        </thead>
        <tbody style={{ background: "white" }}>
          {this.state.osDistributionData.map((data, key) => {
            return (
              <tr key={key}>
                <td style={{ whiteSpace: "nowrap" }}> {data.machineName} </td>
                <td style={{ whiteSpace: "nowrap" }}>{data.osName}</td>
                <td style={{ whiteSpace: "nowrap",textAlign:"center" }}>
                  {this.getStatusView(data.updated)}
                </td>
                <td style={{ whiteSpace: "nowrap",textAlign:"center" }}>
                  {" "}
                  {this.getStatusView(data.activated)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  getStatusView(status, text) {
    if (status) {
      return (
        <span
          style={{
            backgroundColor: "#008000",
            color: "white",
            height: 16,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 4,
            width:100,
          }}
        >
          {text ? text : "Yes"}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "red",
            color: "white",
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 4,
          }}
        >
          {text ? text : "No"}
        </span>
      );
    }
  }
}
