import React from "react";
import NavBar from "../Dashboard/DashboardNavbar";
import ConfigurationsMain from "./ConfigurationsMain";
import ls from "local-storage";

export default class ConfigurationsLayout extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    const isAuthenticated = ls.get("isAuthenticated");
    if (!isAuthenticated) {
      window.location.assign("/");
    }
    return (
      <div className="bm-website">
        <NavBar />
        <ConfigurationsMain
          isToggleAttempted={this.props.isToggleAttempted}
          toggleAllowedCallback={this.props.toggleAllowedCallback}
        />
      </div>
    );
  }
}
