import React from "react";
import { Row, Col, Spinner } from "reactstrap";
import * as API from "../../../utils/api";
import "../../Dashboard.css";
import * as CardStyles from "../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import EmailPaginatedTable from "../../Discover/paginatedTables/EmailPaginatedTable";
import BarChart3dVertical from "../../generic/BarChart3dVertical";

export default class MonEmailIncidents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      Column1Name: "User Name",
      Column2Name: "Current Usage",
      Column3Name: "Usage Limit",
      Column4Name: "",
      Column5Name: "",
      ReportTitle: "Top 5 Email Users",
      OrgusageLimit: "",
      OrgcurrentUsage: "",
      username: "",
      UserusageLimit: "",
      UsercurrentUsage: "",
      policyName: "",
      count: "",
      graphArrayData: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.MonitorEmailEvents()
      .then((data) => {
        const dlpStatsValue = data.entity.map((element, index) => {
          return {
            infoTypes: element.ruleEvents.map((it, index) => {
              return { xAxis: it.ruleName, yAxis1: it.count };
            }),
          };
        });
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Email Events
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (
      this.state.dlpStatsArray === null ||
      (this.state.dlpStatsArray.length > 0 &&
        this.state.dlpStatsArray[0].infoTypes.length === 0)
    ) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Email Events
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>Email Events</Card.Header>
          <Card.Body class="bm-card-body">
            {this.state.dlpStatsArray.map((element, index) => {
              return (
                <div sm="4" key={index}>
                  <Row>
                    <Col sm="4">
                      <BarChart3dVertical
                        data={element.infoTypes}
                        chartId="monEmailBarchartDivVertical"
                      />
                    </Col>
                    <Col sm="8">
                      <EmailPaginatedTable />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Card.Body>
        </Card>
      </div>
    );
  }
}
