import React, { Component } from "react";
import * as API from "../../../utils/api";
import * as Utils from "../../../utils/Utils";
import Table from "react-bootstrap/Table";
import { Spinner, Row, Col } from "reactstrap";
import UltimatePaginationBootstrap4 from "react-ultimate-pagination-bootstrap-4";

export default class EmailPaginatedTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      dlpStatsArray: [],
      pageSize: 10,
      pagesCount: 0,
      hidden: true,
      totalResults: 0,
      isLoading: true,
    };
    this.populateDlpStats = this.populateDlpStats.bind(this);
    this.emailEventIdClicked = this.emailEventIdClicked.bind(this);
  }

  componentDidMount() {
    this.getFirstPageIncidents(0);
  }

  getFirstPageIncidents(currentPage) {
    this.state.isLoading = true
    API.getEmailEventsPaginated(currentPage, this.state.pageSize)
      .then((data) => {
        this.state.isLoading = false
        console.log(data);
        this.populateDlpStats(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  emailEventIdClicked(data) {
    console.log("email event clicked " + data.id);
    API.GetEmailEventsById(data.id);
  }

  populateDlpStats(data) {
    let total = data.total;
    let dlpStatsValue = data.entityList.map((element, index) => {
      var emailToArr = element.emailTo.split(",");
      let emailTo =
        emailToArr.length > 1 ? emailToArr[0] + ",..." : emailToArr[0];
      return {
        id: element.id,
        rulesVoilated: element.infoType.replace(new RegExp(",", "g"), ", "),
        emailTo: emailTo,
        createdOn: Utils.parseDate(element.createdOn),
        emailFrom: element.emailFrom,
        Severity: element.topSeverity,
      };
    });
    this.setState({ totalResults: total });

    let totalPages = total / data.size;

    this.setState({ hidden: false });
    if (totalPages >= 1) {
      this.setState({ pagesCount: Math.ceil(totalPages) });
    }

    this.setState({ dlpStatsArray: dlpStatsValue });
  }

  handleClick(index) {
    this.setState({
      currentPage: index,
    });
    this.getFirstPageIncidents(index);
  }

  renderList() {
    if (this.state.isLoading == true) {
      return (
        <div style={{ paddingLeft: "350px", paddingTop: "140px", paddingBottom: "100px" }}>
          <Spinner type="grow" color="primary" />
          <Spinner type="grow" color="secondary" />
          <Spinner type="grow" color="success" />
          <Spinner type="grow" color="danger" />
        </div>
      );
    }
    else {
      return (
        <Table
          hidden={this.state.hidden}
          striped
          responsive
          style={{ background: "white", fontSize: "10px", textAlign: "left" }}
          size="sm"
        >
          <thead style={{ color: "white", background: "grey" }}>
            <tr>
              <th style={{ whiteSpace: "nowrap" }}>Event ID</th>
              <th style={{ whiteSpace: "nowrap" }}>Event Time</th>
              <th style={{ whiteSpace: "nowrap" }}>From</th>
              <th style={{ whiteSpace: "nowrap" }}>To</th>
              <th style={{ whiteSpace: "nowrap" }}>Rules Violated</th>
              <th style={{ whiteSpace: "nowrap" }}>Severity</th>
            </tr>
          </thead>
          <tbody style={{ background: "white" }}>
            {this.state.dlpStatsArray.map((data, key) => {
              return (
                <tr key={data.createdOn + data.id}>
                  <td
                    style={{ textDecorationLine: "underline", cursor: "pointer" }}
                    onClick={() => this.emailEventIdClicked(data)}
                  >
                    {data.id}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>{data.createdOn}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{data.emailFrom}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{data.emailTo}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{data.rulesVoilated}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{data.Severity}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }
  }

  renderPagination() {
    const { currentPage, pagesCount } = this.state;
    if (pagesCount <= 1) {
      return <div />;
    } else {
      return (
        <UltimatePaginationBootstrap4
          currentPage={currentPage + 1}
          totalPages={pagesCount}
          onChange={(index) => {
            this.handleClick(index - 1);
          }}
        />
      );
    }
  }

  render() {
    let startIndex = this.state.currentPage * this.state.pageSize + 1;
    let toIndex =
      this.state.pageSize + this.state.pageSize * this.state.currentPage;
    if (toIndex > this.state.totalResults) {
      toIndex = this.state.totalResults;
    }
    return (
      <div>
        {this.renderList()}
        <Row>
          <Col sm="6">
            <p hidden={this.state.hidden} style={{ float: "left" }}>
              Showing {startIndex} to {toIndex} of {this.state.totalResults}{" "}
              results
            </p>
          </Col>
          <Col sm="6">{this.renderPagination()}</Col>
        </Row>
      </div>
    );
  }
}
