import React from "react";
import { Row, Col, Spinner } from "reactstrap";
import * as API from "../../../../utils/api";
import "../../../Dashboard.css";
import * as CardStyles from "../../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import PieChart3D from "../../../generic/PieChart3D";
import BarChart3dVertical from "../../../generic/BarChart3dVertical";
import AvDistributionTable from "./AvDistributionTable";

export default class AntivirusDistribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      avDistributionData: [],
      barAvDistributionData: [],
      avRunningData: [],
      avUpdatedData: [],
      avDetailsData: [],
      chartId: props.chartId,
    };
  }

  componentDidMount() {
    API.getAvDetails()
      .then((data) => {
        this.setState({ avDetailsData: data });
        console.log(data);
        let map = {};
        for (let entity of data) {
          let avName = entity.name;
          map[avName] = !map[avName] ? 1 : map[avName] + 1;
        }
        let avDistData = this.state.avDistributionData;
        for (let key in map) {
          avDistData.push({ avName: key, count: map[key] });
        }
        this.setState({ avDistributionData: avDistData });

        let barAvDistData = this.state.barAvDistributionData;
        for (let key in map) {
          barAvDistData.push({
            xAxis: key,
            yAxis1: map[key],
            yAxis1Label: "AV Count",
          });
        }
        this.setState({ barAvDistributionData: barAvDistData });

        map = {};
        for (let entity of data) {
          let running =
            entity.protectionStatus === "ON" ? "Running" : "Not running";
          map[running] = !map[running] ? 1 : map[running] + 1;
        }
        let runningDistData = this.state.avRunningData;
        for (let key in map) {
          runningDistData.push({ running: key, count: map[key] });
        }
        this.setState({ avRunningData: runningDistData });

        map = {};
        for (let entity of data) {
          let updated =
            entity.signatureStatus === "UP_TO_DATE" ? "Updated" : "Not updated";
          map[updated] = !map[updated] ? 1 : map[updated] + 1;
        }
        let updatedDistData = this.state.avUpdatedData;
        for (let key in map) {
          updatedDistData.push({ updated: key, count: map[key] });
        }
        this.setState({ avUpdatedData: updatedDistData });

        this.setState({ dataLoaded: true });
      })
      .catch((error) => console.log(error));
  }

  render() {
    console.log("Inside AvDistribution");
    console.log(this.state.osDistributionData);
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Antivirus Status
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (
      this.state.avDetailsData == null ||
      this.state.avDetailsData.length <= 0
    ) {
      return (
        <div>
          <Card className="bm-center-card" style={{ minHeight: "300px" }}>
            <Card.Header style={CardStyles.cardHeader}>
              Antivirus Status
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <Card className="bm-center-card1">
        <Card.Header style={CardStyles.cardHeader}>
          Antivirus Status
        </Card.Header>
        <Card.Body class="bm-card-body">
          <Row>
            <Col sm="6">
              <Row>
                <Col sm="12">
                  <BarChart3dVertical
                    data={this.state.barAvDistributionData}
                    chartId={this.props.chartId + "_barDistribution"}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="6">
                  <PieChart3D
                    data={this.state.avRunningData}
                    chartId={this.props.chartId + "_running"}
                    value="count"
                    category="running"
                    innerRadius="30"
                  />
                </Col>
                <Col sm="6">
                  <PieChart3D
                    data={this.state.avUpdatedData}
                    chartId={this.props.chartId + "_updated"}
                    value="count"
                    category="updated"
                    innerRadius="30"
                  />
                </Col>
              </Row>
            </Col>

            <Col sm="6">
              <AvDistributionTable
                avDistributionData={this.state.avDetailsData}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
