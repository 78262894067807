import React from "react";
import { Row, Col, Spinner } from "reactstrap";
import * as API from "../../../../utils/api";
import "../../../Dashboard.css";
import * as CardStyles from "../../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import PieChart3D from "../../../generic/PieChart3D";
import AgentStatusDetailsTable from "./AgentStatusDetailsTable";

export default class AgentServiceStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      agentStatusDetails: [],
      agentRunningData: [],
      chartId: props.chartId,
    };
  }

  componentDidMount() {
    API.getAgentRunningStatus()
      .then((data) => {
        data.entity = data.entity.filter(e => !e.uninstalled);
        this.setState({ agentStatusDetails: data.entity });

        let map = {};
        for (let entity of data.entity) {
          let agentRunning = entity.running ? "Running" : "Not Running";
          map[agentRunning] = !map[agentRunning] ? 1 : map[agentRunning] + 1;
        }
        let agentRunningDist = this.state.agentRunningData;
        for (let key in map) {
          agentRunningDist.push({ running: key, count: map[key] });
        }
        this.setState({ agentRunningData: agentRunningDist });

        this.setState({ dataLoaded: true });
      })
      .catch((error) => console.log(error));
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Bytemonk Service Status
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (
      this.state.agentStatusDetails == null ||
      this.state.agentStatusDetails.length <= 0
    ) {
      return (
        <div>
          <Card className="bm-center-card" style={{ minHeight: "300px" }}>
            <Card.Header style={CardStyles.cardHeader}>
              Bytemonk Service Status
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }
    return (
      <Card className="bm-center-card">
        <Card.Header style={CardStyles.cardHeader}>
          Bytemonk Service Status
        </Card.Header>
        <Card.Body class="bm-card-body">
          <Row>
            <Col sm="6">
              <PieChart3D
                data={this.state.agentRunningData}
                chartId={this.props.chartId + "_running"}
                value="count"
                category="running"
                innerRadius="50"
              />
            </Col>
            <Col sm="6">
              <AgentStatusDetailsTable
                agentStatusDetailsData={this.state.agentStatusDetails}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
