import React from "react";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "../../components/Dashboard.css";
import "../Monitor/monitor.css";
import DisFileIncidents from "../Discover/Incidents/DisFileIncidents";
import DisTopDetections from "./Incidents/DisTopDetections";
import DisGdriveIncidents from "./Incidents/DisGDriveIncidents";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DiscoverSystem from "./DiscoverSystem";

export default class DiscoveryMain extends React.Component {
  constructor(props) {
    super(props);
    if(sessionStorage.getItem("discoverTab")==null){
      sessionStorage.setItem("discoverTab","1")
      sessionStorage.setItem("discoverTabText", "Discover: Files")
    }
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: props.activeTab ? props.activeTab : sessionStorage.getItem("discoverTab"),
      tabText: sessionStorage.getItem("discoverTabText"),
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    let tabTextVal;
    if (selectedTab === "1") {
      tabTextVal = "Discover: Files";
      sessionStorage.setItem("discoverTab","1")
      sessionStorage.setItem("discoverTabText", "Discover: Files")
    } else if (selectedTab === "2") {
      tabTextVal = "Discover: Emails";
      sessionStorage.setItem("discoverTab","2")
      sessionStorage.setItem("discoverTabText", "Discover: Emails")
    } else if (selectedTab === "3") {
      tabTextVal = "Discover: Drive";
      sessionStorage.setItem("discoverTab","3")
      sessionStorage.setItem("discoverTabText", "Discover: Drive")
    } else if (selectedTab === "4") {
      tabTextVal = "Discover: System";
      sessionStorage.setItem("discoverTab","4")
      sessionStorage.setItem("discoverTabText", "Discover: System")
    }

    this.setState({
      activeTab: selectedTab,
      tabText: tabTextVal,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="dashboard-header">
          {this.state.tabText}
          <hr style={{ marginTop: "-1px" }}></hr>
        </div>
        <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect}>
          <Tab eventKey={1} title="Files" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              Top Policy violations
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <DisFileIncidents />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <DisTopDetections />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>
          <Tab eventKey={3} title="Drive" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              Top Policy violations
            </div>
            <Nav card>
              <NavItem>
                <NavLink
                  className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="3" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <DisGdriveIncidents />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>

          <Tab eventKey={4} title="System" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              System health status
            </div>
            <DiscoverSystem />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
