import React, { Component } from "react";
import { changeNumberOfData } from "./utils";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  Area,
  AreaChart,
} from "recharts";
import _ from "lodash";
import "../../../../components/Dashboard.css";

var tempDate = new Date();
var yesterdayDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
var day5 = new Date(yesterdayDate.getTime() - 24 * 60 * 60 * 1000);
var day4 = new Date(day5.getTime() - 24 * 60 * 60 * 1000);
var day3 = new Date(day4.getTime() - 24 * 60 * 60 * 1000);
var day2 = new Date(day3.getTime() - 24 * 60 * 60 * 1000);
var day1 = new Date(day2.getTime() - 24 * 60 * 60 * 1000);

var dayMapping = {};
dayMapping["today"] =
  tempDate.getDate() +
  "-" +
  (tempDate.getMonth() + 1) +
  "-" +
  tempDate.getFullYear();
dayMapping["day6"] =
  yesterdayDate.getDate() +
  "-" +
  (yesterdayDate.getMonth() + 1) +
  "-" +
  yesterdayDate.getFullYear();
dayMapping["day5"] =
  day5.getDate() + "-" + (day5.getMonth() + 1) + "-" + day5.getFullYear();
dayMapping["day4"] =
  day4.getDate() + "-" + (day4.getMonth() + 1) + "-" + day4.getFullYear();
dayMapping["day3"] =
  day3.getDate() + "-" + (day3.getMonth() + 1) + "-" + day3.getFullYear();
dayMapping["day2"] =
  day2.getDate() + "-" + (day2.getMonth() + 1) + "-" + day2.getFullYear();
dayMapping["day1"] =
  day1.getDate() + "-" + (day1.getMonth() + 1) + "-" + day1.getFullYear();

const data = [
  { name: "Page A", uv: 1000, pv: 2400, amt: 2400, uvError: [75, 20] },
  { name: "Page B", uv: 300, pv: 4567, amt: 2400, uvError: [90, 40] },
  { name: "Page C", uv: 280, pv: 1398, amt: 2400, uvError: 40 },
  { name: "Page D", uv: 200, pv: 9800, amt: 2400, uvError: 20 },
  { name: "Page E", uv: 278, pv: null, amt: 2400, uvError: 28 },
  { name: "Page F", uv: 189, pv: 4800, amt: 2400, uvError: [90, 20] },
  { name: "Page G", uv: 189, pv: 4800, amt: 2400, uvError: [28, 40] },
  { name: "Page H", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Page I", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Page J", uv: 189, pv: 4800, amt: 2400, uvError: [15, 60] },
];

const data01 = [
  { day: "05-01", weather: "sunny" },
  { day: "05-02", weather: "sunny" },
  { day: "05-03", weather: "cloudy" },
  { day: "05-04", weather: "rain" },
  { day: "05-05", weather: "rain" },
  { day: "05-06", weather: "cloudy" },
  { day: "05-07", weather: "cloudy" },
  { day: "05-08", weather: "sunny" },
  { day: "05-09", weather: "sunny" },
];

const data02 = [
  { name: "Page A", uv: 300, pv: 2600, amt: 3400 },
  { name: "Page B", uv: 400, pv: 4367, amt: 6400 },
  { name: "Page C", uv: 300, pv: 1398, amt: 2400 },
  { name: "Page D", uv: 200, pv: 9800, amt: 2400 },
  { name: "Page E", uv: 278, pv: 3908, amt: 2400 },
  { name: "Page F", uv: 189, pv: 4800, amt: 2400 },
  { name: "Page G", uv: 189, pv: 4800, amt: 2400 },
];

const initialState = {
  data,
  data01,
  data02,
  opacity: 1,
  anotherState: false,
};

export default class BMLineChart extends Component {
  static displayName = "LineChartDemo";

  state = initialState;

  handleChangeData = () => {
    this.setState(() => _.mapValues(initialState, changeNumberOfData));
  };

  handleClick = (data, e) => {
    console.log(data);
  };

  handleLineClick = (data, e) => {
    console.log("callback", data, e);
  };

  handleLegendMouseEnter = () => {
    this.setState({
      opacity: 0.5,
    });
  };

  handleClickDot = (data, e) => {
    console.log("dot click", data, e);
  };

  handleLegendMouseLeave = () => {
    this.setState({
      opacity: 1,
    });
  };

  handleChangeAnotherState = () => {
    this.setState({
      anotherState: !this.state.anotherState,
    });
  };

  render() {
    return (
      <div className="bm-barchart-data">
        <div
          style={{
            position: "relative",
            right: "250px",
            width: "130%",
            height: "100%",
          }}
        >
          <ResponsiveContainer className="container" height={250} width="100%">
            <AreaChart
              height={400}
              data={this.props.data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <Legend />
              <XAxis dataKey="hour" />
              <YAxis />
              <Tooltip />
              <Area
                name={dayMapping["day1"]}
                type="monotone"
                dataKey="day1"
                stackId="1"
                stroke="#1BA68D"
                fill="#1BA68D"
              />
              <Area
                name={dayMapping["day2"]}
                type="monotone"
                dataKey="day2"
                stackId="1"
                stroke="#E7DA4F"
                fill="#E7DA4F"
              />
              <Area
                name={dayMapping["day3"]}
                type="monotone"
                dataKey="day3"
                stackId="1"
                stroke="#E77624"
                fill="#E77624"
              />
              <Area
                name={dayMapping["day4"]}
                type="monotone"
                dataKey="day4"
                stackId="1"
                stroke="#0f7acc"
                fill="#0f7acc"
              />
              <Area
                name={dayMapping["day5"]}
                type="monotone"
                dataKey="day5"
                stackId="1"
                stroke="#64297B"
                fill="#64297B"
              />
              <Area
                name={dayMapping["day6"]}
                type="monotone"
                dataKey="day6"
                stackId="1"
                stroke="#17BECF"
                fill="#17BECF"
              />
              <Area
                name={dayMapping["today"]}
                type="monotone"
                dataKey="today"
                stackId="1"
                stroke="#F9F871"
                fill="#F9F871"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}
