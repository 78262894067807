import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../components/Dashboard.css";
import "../Monitor/monitor.css";
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import MonitorReport from "./MonitorReport";
import DiscoverReport from "./DiscoverReport";

export default class ReportsMain extends React.Component {
  constructor(props) {
    if (sessionStorage.getItem("reportTab") == null) {
      sessionStorage.setItem("reportTab","1")
      sessionStorage.setItem("reportTabText", "Reports: Monitor")
    }
    super(props);
    this.state = {
      activeTab: sessionStorage.getItem("reportTab"),
      tabText: sessionStorage.getItem("reportTabText"),
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    let tabTextVal;
    if (selectedTab === "1") {
      tabTextVal = "Reports: Monitor";
      sessionStorage.setItem("reportTab","1")
      sessionStorage.setItem("reportTabText", "Reports: Monitor")
    }
    if (selectedTab === "2") {
      tabTextVal = "Reports: Discover";
      sessionStorage.setItem("reportTab","2")
      sessionStorage.setItem("reportTabText", "Reports: Discover")
    }
    this.setState({
      activeTab: selectedTab,
      tabText: tabTextVal,
    });
  }

  render() {
    return (
      <div>
        <div className="dashboard-header">
          {this.state.tabText}
          <hr style={{ marginTop: "-1px" }}></hr>
        </div>
        <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect}>
          <Tab eventKey={1} title="Monitor" tabClassName="bm-inactive-tab">
            <hr
              style={{
                marginTop: "1px",
                size: "10",
                height: "2px",
                backgroundColor: "#004178",
              }}
            ></hr>
            <div
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="bm-summary-bar"
            >
              Detailed reports
            </div>
            <Nav card>
              <NavItem>
                <NavLink className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }} >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <MonitorReport />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>
          <Tab eventKey={2} title="Discover" tabClassName="bm-inactive-tab">
            <hr style={{ marginTop: "1px", size: "10", height: "2px", backgroundColor: "#004178" }}/>
            <div style={{ marginTop: "10px", marginBottom: "10px" }} className="bm-summary-bar" >
              Detailed reports
            </div>
            <Nav card>
              <NavItem>
                <NavLink className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="2" className="dashboard-tab-bg">
                <Row>
                  <Col sm="12">
                    <DiscoverReport />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
