import React from "react";
import { Table, Spinner } from "reactstrap";
import * as API from "../../../utils/api";
import * as Utils from "../../../utils/Utils";
import "../../Dashboard.css";
import * as CardStyles from "../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

export default class USBDeviceLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      dataLoaded: false,
    };
    this.state.handleFullDowload = this.handleFullDowload.bind(this);
    this.handleEventDownload = this.handleEventDownload.bind(this);
  }

  componentDidMount() {
    API.eventLogApi("USB")
      .then((data) => {
        console.log("In usbEventLog API " + JSON.stringify(data));

        data = data.filter((i) => i.eventName === "USB_REMOVED");

        const dlpStatsValue = data.map((element) => {
          var usbModel;
          var serialNumber;
          Object.keys(element.eventAttributes).forEach(function (key) {
            if (key.includes("MODEL")) {
              usbModel = element.eventAttributes[key];
            }
            if (key.includes("SERIAL_NUMBER")) {
              serialNumber = element.eventAttributes[key];
            }
          });
          let fileNames = "";
          var fileNamesArr = element.fileNames.split(",");
          if (fileNamesArr != null && fileNamesArr.length === 1) {
            fileNames = fileNamesArr[0];
          } else if (fileNamesArr != null && fileNames.length === 2) {
            fileNames = fileNamesArr[0] + ", " + fileNamesArr[1];
          } else {
            fileNames = fileNamesArr[0] + ", " + fileNamesArr[1] + ",...";
          }

          return {
            eventId: element.id,
            connectedTo: element.machineName,
            connectedAt: Utils.parseDate(element.eventStart),
            disConnectedAt: Utils.parseDate(element.eventEnd),
            CopiedFiles: fileNames,
            usbModel: usbModel + " (" + serialNumber + ")",
            username: element.userName,
          };
        });
        console.log(JSON.stringify(dlpStatsValue));
        this.setState({ dlpStatsArray: dlpStatsValue });
        this.setState({ dataLoaded: true });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  handleEventDownload(data) {
    console.log("EventId Clicked " + data.eventId);
    API.usbEventsReportDownload(data.eventId, 7);
  }

  handleFullDowload(e) {
    console.log("Full download clicked");
    API.usbEventsReportDownload(null, 7);
  }

  render() {
    let currDataArr = [];

    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              USB Device Control Log
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (this.state.dlpStatsArray && this.state.dlpStatsArray.length <= 0) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              USB Device Control Log
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            USB Device Control Log
          </Card.Header>
          <Card.Body class="bm-card-body">
            <div style={{ margin: "5px" }}>
              <Table
                responsive
                striped
                style={{
                  background: "white",
                  fontSize: "12px",
                  textAlign: "left",
                }}
                size="sm"
              >
                <thead style={{ color: "white", background: "grey" }}>
                  <tr>
                    <th>Event ID</th>
                    <th>USB Model</th>
                    <th>User Name</th>
                    <th>Connected To</th>
                    <th>Connected At</th>
                    <th>Disconnected At</th>
                    <th>Copied File</th>
                  </tr>
                </thead>
                <tbody style={{ background: "white" }}>
                  {this.state.dlpStatsArray.map((data, key) => {
                    currDataArr[0] = data;
                    return (
                      <tr key={data.eventId}>
                        <td
                          style={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => this.handleEventDownload(data)}
                        >
                          {data.eventId}
                        </td>
                        <td>{data.usbModel}</td>
                        <td>{data.username}</td>
                        <td>{data.connectedTo}</td>
                        <td>{data.connectedAt}</td>
                        <td>{data.disConnectedAt}</td>
                        <td>{data.CopiedFiles}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Button
                style={{ float: "left" }}
                onClick={() => this.handleFullDowload()}
                raised="true"
                color="primary"
              >
                Download
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
