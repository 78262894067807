import React from "react";
import { Row, Col, Spinner } from "reactstrap";
import * as API from "../../../../utils/api";
import "../../../Dashboard.css";
import Card from "react-bootstrap/Card";
import PieChart3D from "../../../generic/PieChart3D";
import OsDistributionTable from "./OsDistributionTable";
import BarChart3dVertical from "../../../generic/BarChart3dVertical";
import * as CardStyles from "../../../../styles/CardStyles";

export default class OsDistribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      osDistributionData: [],
      barOsDistributionData: [],
      osActivationData: [],
      osUpdatedData: [],
      osDetailsData: [],
      chartId: props.chartId,
    };
  }

  componentDidMount() {
    API.getOsDetails()
      .then((data) => {
        this.setState({ osDetailsData: data });

        let map = {};
        for (let entity of data) {
          let osName = entity.osName;
          map[osName] = !map[osName] ? 1 : map[osName] + 1;
        }
        let osDistData = this.state.osDistributionData;
        for (let key in map) {
          osDistData.push({ osName: key, count: map[key] });
        }
        this.setState({ osDistributionData: osDistData });

        let barOsDistData = this.state.barOsDistributionData;
        for (let key in map) {
          barOsDistData.push({
            xAxis: key,
            yAxis1: map[key],
            yAxis1Label: "OS Count",
          });
        }
        barOsDistData.sort((a, b) => {return b.yAxis1 - a.yAxis1});
        this.setState({ barOsDistributionData: barOsDistData });

        map = {};
        for (let entity of data) {
          let activated = entity.activated ? "Activated" : "Not activated";
          map[activated] = !map[activated] ? 1 : map[activated] + 1;
        }
        let activationDistData = this.state.osActivationData;
        for (let key in map) {
          activationDistData.push({ activated: key, count: map[key] });
        }
        this.setState({ osActivationData: activationDistData });

        map = {};
        for (let entity of data) {
          let updated = entity.updated ? "Updated" : "Not updated";
          map[updated] = !map[updated] ? 1 : map[updated] + 1;
        }
        let updatedDistData = this.state.osUpdatedData;
        for (let key in map) {
          updatedDistData.push({ updated: key, count: map[key] });
        }
        this.setState({ osUpdatedData: updatedDistData });

        this.setState({ dataLoaded: true });
      })
      .catch((error) => console.log(error));
  }

  render() {
    console.log("Inside OsDistribution");
    console.log(this.state.osDistributionData);
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              OS Status
            </Card.Header>
            <Card.Body class="bm-card-body">
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (
      this.state.osDetailsData == null ||
      this.state.osDetailsData.length <= 0
    ) {
      return (
        <div>
          <Card className="bm-center-card" style={{ minHeight: "300px" }}>
            <Card.Header style={CardStyles.cardHeader}>
              OS Status
            </Card.Header>
            <Card.Body class="bm-card-body">
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <Card className="bm-center-card1">
        <Card.Header style={CardStyles.cardHeader}>
          OS Status
        </Card.Header>
        <Card.Body class="bm-card-body">
          <Row>
            <Col sm="6">
              <Row>
                <Col sm="12">
                  <BarChart3dVertical
                    data={this.state.barOsDistributionData}
                    chartId={this.props.chartId + "_barDistribution"}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="6">
                  <PieChart3D
                    data={this.state.osActivationData}
                    chartId={this.props.chartId + "_activation"}
                    value="count"
                    category="activated"
                    innerRadius="30"
                  />
                </Col>
                <Col sm="6">
                  <PieChart3D
                    data={this.state.osUpdatedData}
                    chartId={this.props.chartId + "_updated"}
                    value="count"
                    category="updated"
                    innerRadius="30"
                  />
                </Col>
              </Row>
            </Col>

            <Col sm="6">
              <OsDistributionTable
                osDistributionData={this.state.osDetailsData}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
