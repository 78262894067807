import React, { Component } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../Dashboard/Dashboard.css";

am4core.useTheme(am4themes_animated);

export default class PieChart3D extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      innerRadius: 50,
    };
  }

  componentDidMount() {
    let chart = am4core.create(this.props.chartId, am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;
    chart.data = this.props.data;
    chart.innerRadius = this.props.innerRadius;

    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = this.props.value;
    series.dataFields.category = this.props.category;
    series.labels.template.text = "{category}: {value.value}";
    series.labels.template.nonScaling = false;
    series.alignLabels = false;

    series.ticks.template.disabled = true;
    series.labels.template.bent = false;
    series.labels.template.radius = 3;
    series.labels.template.padding(-6, -6, -6, -6);

    // Create a base filter effect (as if it's not there) for the hover to return to
    var shadow = series.slices.template.filters.push(
      new am4core.DropShadowFilter()
    );
    shadow.opacity = 0;

    // Slightly shift the shadow and make it more prominent on hover
    var hoverState = series.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists
    var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    this.chart = chart;
  }

  componentWillReceiveProps(newProps) {
    this.chart.data = newProps.data;
    this.chart.innerRadius = newProps.innerRadius;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id={this.props.chartId}></div>;
  }
}
