import React, { Component } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../Dashboard/Dashboard.css";

am4core.useTheme(am4themes_animated);

export default class PieChart3D extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
    };
  }

  componentDidMount() {
    var chart = am4core.create("barchartdiv", am4charts.XYChart3D);

    chart.data = this.props.data;

    // Create X axis
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "xAxis";
    categoryAxis.numberFormatter.numberFormat = "#";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;

    //Create Y axis
    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueX = "yAxis1";
    series.dataFields.categoryY = "xAxis";
    series.name = "Network Usage";
    series.columns.template.propertyFields.fill = am4core.color;
    series.columns.template.tooltipText = "{valueX}";
    //series.columns.template.column3D.stroke = am4core.color("#ff0000");
    series.columns.template.column3D.strokeOpacity = 0.2;
    series.columns.template.width = 100;

    let valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = "{valueX}";
    valueLabel.label.fontSize = 10;
    valueLabel.label.truncate = false;
    valueLabel.label.horizontalCenter = "left";
    valueLabel.label.dx = 0;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id="barchartdiv"></div>;
  }
}
