import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import "../../../components/Dashboard.css";
import HealthStatus from "./Data/HealthStatus";

export default class SummaryOverview extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    return (
      <div className="bm-dashboard-partition">
        <Nav card>
          <NavItem>
            <NavLink
              className="{classnames({ active: this.state.activeTab === '1' })} bm-tab-header"
              onClick={() => {
                this.toggle("1");
              }}
            >
              Health Summary
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" className="dashboard-tab-bg">
            <Row>
              <Col sm="12">
                <HealthStatus />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
