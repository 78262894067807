import React from "react";
import { Spinner, Row, Col } from "reactstrap";
import * as API from "../../../utils/api";
import "../../../components/Dashboard.css";
import * as CardStyles from "../../../styles/CardStyles";
import Card from "react-bootstrap/Card";
import BackupOverviewTable from "./BackupOverviewTable";
import PieChart3D from "../../generic/PieChart3D";

export default class BackupOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlpStatsArray: [],
      bkRunningData: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    API.dataBackupSummaryApi()
      .then((data) => {
        this.setState({ dataLoaded: true });
        this.setState({ dlpStatsArray: data });

        let map = {};
        if (data.entity && data.entity.length > 0) {
          for (let entity of data.entity) {
            let bkRunning = entity.running ? "Happening" : "Not Happening";
            map[bkRunning] = !map[bkRunning] ? 1 : map[bkRunning] + 1;
          }
        }
        let bkRunningDist = this.state.bkRunningData;
        for (let key in map) {
          bkRunningDist.push({ running: key, count: map[key] });
        }
        this.setState({ bkRunningData: bkRunningDist });
      })
      .catch((e) => {
        console.log("Error in Component Mount -> api fetch " + e);
      });
  }

  render() {
    if (!this.state.dataLoaded) {
      return (
        <div>
          <Card className="bm-center-card">
            <Card.Header style={CardStyles.cardHeader}>
              Data Backup Summary
            </Card.Header>
            <Card.Body>
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (
      this.state.dlpStatsArray.entity === null || this.state.dlpStatsArray.entity === undefined ||
      this.state.dlpStatsArray.entity.length <= 0
    ) {
      return (
        <div>
          <Card className="bm-center-card" style={{ minHeight: "300px" }}>
            <Card.Header style={CardStyles.cardHeader}>
              Data Backup Summary
            </Card.Header>
            <Card.Body>
              <h6>No Data Found</h6>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card className="bm-center-card">
          <Card.Header style={CardStyles.cardHeader}>
            Data Backup Summary
          </Card.Header>
          <Card.Body>
            <Row>
              <Col sm="4">
                <PieChart3D
                  data={this.state.bkRunningData}
                  chartId={this.props.chartId + "_bkHappening"}
                  value="count"
                  category="running"
                  innerRadius="30"
                />
              </Col>
              <Col sm="8">
                <BackupOverviewTable
                  backupOverviewData={this.state.dlpStatsArray}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
